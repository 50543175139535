//#region Summary
//pure functional component
//Customize  Input control accepts props for input
//#endregion Summary
import React from "react";
import PropTypes from "prop-types";
import { FormControl, makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import  "./input.css";
const useStyles = makeStyles((theme)=>({
  
  controlLabel:{
    lineHeight:'100%',
    fontWeight:500,
  },
  error__label: {
    color: theme.palette.error.main,
  },
  customTextField: {
    "& .MuiInputAdornment-root":{
  padding: "19.5px 10px",
  marginLeft:"-14px",
backgroundColor:  theme.palette.divider,
borderTopLeftRadius:"4px",
borderBottomLeftRadius:"4px",

    },
    
    "& input::placeholder": {
      fontSize: "14px"
    },

  //   '& .MuiOutlinedInput-root': {
  //     '& fieldset': {
  //      borderColor: theme.palette.inputBorder.main,
      
  //     },
  //     '&:hover fieldset': {
  //       borderColor: theme.palette.inputBorder.main,
      
  //     },
  //     '&.Mui-focused fieldset': {
  //      borderColor: theme.palette.inputBorder.main,
  //     },
  // },
},
  
}));
const Input = ({ name,
  prompt,
  isRequiredLable,
  label,
  onBlur,
  placeHolder,
  value,
  variant,
  onChange,
  fullWidth,
  errors=null,
  required=false,
  autoComplete,
  ...others}) => {
  const classes = useStyles();
  // const {
  //   name,
  //   prompt,
  //   label,
  //   onBlur,
  //   placeHolder,
  //   value,
  //   variant,
  //   onChange,
  //   fullWidth,
  //   errors=null,
  //   required=false,
  //   ...others
  //} = props;

  return (
    <FormControl component="fieldset">
        {prompt && (
      <Typography className={classes.controlLabel} component={"label"} htmlFor={name} variant="subtitle1"
    
       >           
        {prompt}{ (required || isRequiredLable) && <span className={classes.error__label}>*</span>}
      </Typography>
    
    )}
      <div className={'formControl'}>
        <TextField
        required={required}
        className={classes.customTextField}
          variant={variant || "outlined"}
          label={!prompt && label}
          placeholder={placeHolder}
          name={name}
          value={value}
          onChange={onChange}
          fullWidth={fullWidth || false}
          onBlur={onBlur}
        autoComplete={autoComplete || "off"}
          {...(errors && { error:true, helperText:errors})}
          {...others}
         
        />
        
      </div>
    </FormControl>
  );
};
Input.propTypes = {
  placeHolder:PropTypes.string,
  variant: PropTypes.oneOf(["filled","outlined", "standard"]),
  prompt: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
export default Input;
