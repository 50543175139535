import { Paper } from '@material-ui/core';
import React from 'react'
import { useState,useEffect, useCallback } from 'react';
import AnimationFade from '../Animation/AnimationFade';
import SubscribersFilterBar from './SubscribersFilterBar';
import SubscribersItem from './SubscribersItem';
import * as usersApi from "../../services/Api/Request/usersApi";
import { ConfirmDialog, Notification } from '../../controls';
const Subscribers = () => {
  //#region component states
  const [confirmInfo, setConfirmInfo] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [info, setInfo] = useState({ isOpen: false, message: "" });
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [isToggle, setIsToggle] = useState(false);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const [companyName, setCompanyName] = useState("");
  //#endregion
//#region get subscribers records with count on changes of dependencies
//dependencies===>page, pageSize, orderBy, order, companyName,isToggle
useEffect(() => {
 const controller=new AbortController();
 const getData =async () => {
  try {
      const qry = {
      pagenumber: page+1 ,
      pageSize: pageSize,
      sortColumn: orderBy,
      sortOrder: order,
      companyName: companyName,
     
    };
    setIsLoading(true);
    setError("");
   const response = await usersApi.getSubscribers(qry,controller?.signal);
    const responseData = await response.data;
    setCount(responseData.accountsCount);
    setData(responseData.accounts);
    setIsLoading(false);
    {responseData.accountsCount===0 ? setError("No Record Found") : setError("");  }
  } catch (error) {
       error.response?.data
      ? setError(error.response.data)
      : setError("Something Went Wrong!");
    setIsLoading(false);
    setCount(0);
  }
};
  getData();
return () => {
    setData([]);
    controller.abort();
  };
}, [page, pageSize, orderBy, order, companyName,isToggle]);
//#endregion
  //#region handletable  sorting
  const handleRequestSort = useCallback((property, event) => {
   
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  });
  //#endregion
   //#region toggler
  const onToggle = async (item) => {
    try {
      setIsLoading(true);
      const itemData = { id: item.id, isActive: !item.isActive };
      const response = await usersApi.toggleSubscriber(itemData);
      const responseData = await response.data;
      setInfo({
        isOpen: true,
        message: responseData,
        type: "success",
      });

      setIsToggle(prev=>!prev);
      setIsLoading(false);
    } catch (error) {
        setInfo({
        isOpen: true,
        message: error.response?.data
        ? error.response.data
        : "Something Went Wrong!",
        type: "error",
      });
      setIsLoading(false);
    }
  };
  const toggleHandler=useCallback((item)=>{
    setConfirmInfo({
      isOpen: true,
      title: item.isActive
        ? "Are You Want To Disable This Record?"
        : "Are You Want To Enable This Record?",
      //  subTitle: `${item.name}`,
      onConfirm: () => {
        onToggle(item);
        setConfirmInfo({
          ...confirmInfo,
          isOpen: false,
        });
      },
    });
  });
  //#endregion
  //#region handles search and reset
  //seacrchQry===from child component SubscribersFilterBar
  const  onSearchClick=(seacrchQry)=>{
    setCompanyName(seacrchQry)
    setOrderBy("");
    setPage(0);

  };
  const onRestClick=()=>{
    setCompanyName('');
    setOrderBy("");
  }
  //#endregion
  return (
    <>
  <Paper>
   <SubscribersFilterBar onSearchClick={onSearchClick} onRestClick={onRestClick}/>
   <AnimationFade>
   <SubscribersItem
        createSortHandler={handleRequestSort}
        records={data}
        isLoading={isLoading}
        orderBy={orderBy}
        order={order}
        count={count}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        error={error}
        onToggle={toggleHandler}
        />
   </AnimationFade>
  </Paper>
  {info.isOpen && <Notification {...{ info, setInfo }} />}
      {confirmInfo && (
        <ConfirmDialog
          confirmDialog={confirmInfo}
          setConfirmDialog={setConfirmInfo}
        />
      )}
  </>
  )
}

export default Subscribers