//#region 
// <summary>
// axios api for file upload
//CommonHttp used to set base url
//  MasterServices Api To fetch/add/update/delete based on state
//  fetch is used in pagination/search/get by using qry
//</summary> 
//#endregion

import {masterService} from "../Config/masterServices";

export const uploadClientLogo=(formData, clientName)=>{
 
    const uploadUrl=`/MasterApi/FileUpload/ClientsLogo?clientName=${clientName}`; 
    return masterService({
      url: uploadUrl,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  };
  export const removeClientLogo=(values)=>{
 
    const endUrl=`/MasterApi/FileUpload/RemoveClientsLogo`; 
    return masterService({
      url: endUrl,
      data:values,
      method: "post",   
      
    });
  };
  export const uploadUsersProfilePic=(formData, userName)=>{
 
    const uploadUrl=`/MasterApi/FileUpload/UsersProfilePic?userName=${userName}`; 
    return masterService({
      url: uploadUrl,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  };
  export const removeUsersProfilePic=(values)=>{
 
    const endUrl=`/MasterApi/FileUpload/RemoveUsersProfilePic`; 
    return masterService({
      url: endUrl,
      data:values,
      method: "post",   
      
    });
  };
  export const fileDownload=(values)=>{
    const endUrl=`/MasterApi/FileUpload/Download`;
    // const Authorization = auth
    // ? `${auth?.userId}.${auth?.userName}`.toString("base64")
    // : "";

      return masterService({
        url: endUrl,
      //  headers:{Authorization},
      data:values,
      method: "post",
        responseType: "arraybuffer",
      });
};
export const uploadRTEFile=(formData)=>{
   
    const uploadUrl="/MasterApi/FileUpload/RTEImages";
   
    return masterService({
      url: uploadUrl,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  };
  export const uploadSubscriberLogo=(formData, subscriberName)=>{
 
    const uploadUrl=`/MasterApi/FileUpload/AccountLogo?subscriberName=${subscriberName}`; 
    return masterService({
      url: uploadUrl,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  };
  export const removeSubscriberLogo=(values)=>{
 
    const endUrl=`/MasterApi/FileUpload/RemoveAccountsLogo`; 
    return masterService({
      url: endUrl,
      data:values,
      method: "post",   
      
    });
  };