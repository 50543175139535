import React from 'react'
import { useState } from 'react';

const useTableColFilterMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
   const handleClose = () => {
     setAnchorEl(null);
   };

 
   
  const handleFilter = (event, property) => {
  
    setAnchorEl(event.currentTarget);
  };
  return {
   anchorEl, handleClose, handleFilter}
  
}

export default useTableColFilterMenu