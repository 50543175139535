import React from 'react';
import AnimationFade from '../components/Animation/AnimationFade';
import Subscribers from '../components/Subscribers/Subscribers';
import SubscribersHeader from '../components/Subscribers/SubscribersHeader';
const SubscribersPage = () => {
//#region header subTitle
const subTitle="Manage Subscribers";

//#endregion
  return (
   <>
     <AnimationFade>
   <SubscribersHeader subTitle={subTitle}/>
     </AnimationFade>
        <Subscribers/>
 
   </>
  )
}

export default SubscribersPage