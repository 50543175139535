import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Notification } from "../../controls";
import { IconButton, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useCallback } from "react";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
      "& .MuiIconButton-root":{
    paddingLeft:"0px",
        fontSize:"16px",
        borderRadius:0,
        color:theme.palette.text.primary,
        // paddingTop:"7px",
        // paddingLeft:"10px",
        padding:"0px",
        margin:"7px",
        lineHeight:'100%',
    fontWeight:500,
        '&:hover': {
            outline:0,
            border:0,
            background:"transparent"
        }
       
      },
    },
  
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
   
  }));
const FileDownload = ({apiString,filePath, isIconBtn=false,label}) => {

  const {auth}=useAuth();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = useState({ isOpen: false, message: "" });
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
  
    const handleDownload = useCallback(async () => {
      try {
     
       const data={
        filePath:filePath
       };
        setSuccess(false);
        setLoading(true);
      
        const response = await apiString(data, auth);
        const responseData = await response.data;
        const fileNameHeader = "content-disposition";
        const suggestedFileName = response.headers[fileNameHeader]

          .split("filename=")[1]
          .split(";")[0];
          
          const effectiveFileName =
          suggestedFileName === undefined ? "fileDownloaded" : suggestedFileName;
       
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", effectiveFileName);
        document.body.appendChild(link);
        link.click();
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        setSuccess(true);
        setInfo({  isOpen: true,
          message: error?.response?.data
          ? error.response.data
          : "Something Went wrong!",
            type: "error",});
        setLoading(false);
      }
    });
  return (
    <>
    <div className={classes.wrapper}>
    {isIconBtn ? <IconButton onClick={handleDownload} disableRipple={true} disableFocusRipple={true}
      isProgress={loading}>{label && label}<GetAppIcon /></IconButton>:
    <Button
      label={label}
      variant="contained"
      startIcon={<GetAppIcon />}
      onClick={handleDownload}
      isProgress={loading}
    ></Button>}
    {loading && (
      <CircularProgress size={24} className={classes.buttonProgress} />
    )}
  </div>
   {info.isOpen && <Notification {...{ info, setInfo }} />}
   </>
  )
}

export default FileDownload