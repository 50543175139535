import * as React from 'react';
import { useEffect } from 'react';
import { UNSAFE_NavigationContext as navigationContext } from 'react-router-dom';
export function usePreventNav(blocker, when) {
  const navContext = React.useContext(navigationContext)
   useEffect(() => {
    if (!when) return;

    const unblock = navContext.navigator.block((trns) => {
      const autoUnblockingTrns = {
        ...trns,
        retry() {
          unblock();
          trns.retry();
        },
      };

      blocker(autoUnblockingTrns);
    });

    return unblock;
  }, [navContext, blocker, when]);
}