import React from "react";
import PropTypes from 'prop-types';
//material ui import
import { makeStyles, Button as MuiButton, CircularProgress,Tooltip } from "@material-ui/core";

//CSS STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth:'0',
   minHeight:'35.5px',   
    padding:'5px 15px',
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  wrapper: {
   
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const Button = (props) => {
  const classes = useStyles();
  const { label, color, variant, onClick, className, size, isFullwidth, isProgress=false, isDisabled=false,tooltiptitle, ...other } = props;
 
  return (
    tooltiptitle ?
    <Tooltip title={tooltiptitle} placement="left">
    <div className={classes.wrapper}>
    
    <MuiButton
      className={classes.root + " " + (className || "")}
      variant={variant || 'outlined'}
      color={color || "primary"}
      size={size || "medium"}
      onClick={onClick}
      {...other}
      fullWidth={isFullwidth || false}
     disabled={isProgress || isDisabled}
     disableElevation
    >
     {label || props.children}
    </MuiButton>
     {isProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
    </Tooltip>
    :
    <div className={classes.wrapper}>
    
    <MuiButton
      className={classes.root + " " + (className || "")}
      variant={variant || 'outlined'}
      color={color || "primary"}
      size={size || "medium"}
      onClick={onClick}
      {...other}
      fullWidth={isFullwidth || false}
     disabled={isProgress || isDisabled}
     disableElevation
    >
     {label || props.children}
    </MuiButton>
     {isProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
Button.propTypes={
  label: PropTypes.string.isRequired,
  color:PropTypes.string,
  isProgress:PropTypes.bool,
  variant:PropTypes.oneOf(['contained','outlined','text']),
  onClick:PropTypes.func,
  className:PropTypes.string,
  size:PropTypes.string,
  isFullwidth:PropTypes.bool
};
export default Button;
