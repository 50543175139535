//#region 
// <summary>
// axios api for app user, roles and permissions
//masterService base url used to set base url
// Master Api To fetch/add/update/delete
//  fetch is used in pagination/search/get by using qry
//</summary> 
//#endregion
import { authService } from "../Config/authServices";

  export const login=(values)=>{
    const endUrl="AuthenticationApi/login";
    return authService({
      url:endUrl,
      data:values,
      method:"post",
       
    })
  };
  export const getUserProfile=(qry)=>{
    const endUrl="AuthenticationApi/UserProfile/InfoByAccessToken";
    return authService({
      url:endUrl,
      data:qry,
      method:"post",
       
    })
  }
  export const changePassword=(values)=>{
    const endUrl="AuthenticationApi/UserProfile/ChangePassword";
    return authService({
      url:endUrl,
      data:values,
      method:"post",
       
    })
  }
  export const refresh=(token)=>{
    const endUrl="AuthenticationApi/Login/RefreshToken";
    return authService({
      url:endUrl,
      method:"get",
      //for temp after cookies token sent through cookies 
      headers:{"Authorization":`Bearer ${token}`}//remove this line
   
    
    })
  }
  export const getResetLink=(value)=>{
const endUrl="AuthenticationApi/Login/ForgotPassword";
return authService({
  url:endUrl,
  data:value,
  method:"post",
})
  };
  export const resetassword=(values)=>{
    const endUrl="AuthenticationApi/Login/OTUVerification";
    return authService({
      url:endUrl,
      data:values,
      method:"post",
       
    })
  }