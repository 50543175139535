//Checkpoint Observation:- Annexurefile Upload
//for attach and upload file to server
import {
    Paper,
    FormControl,
    Typography,
    makeStyles,
    IconButton,
    CircularProgress,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  import PropTypes from 'prop-types';
  import * as fileApi from "../../services/Api/Request/fileUploadApi";
  import { Button } from "../../controls";
  import DeleteIcon from "@material-ui/icons/Delete";
  import FileDownload from "../Download/FileDownload";
import { green } from "@material-ui/core/colors";
  const useStyles = makeStyles((theme) => ({
    removeBtn: {
      color: theme.palette.error.main,
    },
  
    error__label: {
      color: theme.palette.error.main,
      fontWeight: 400,
    },
    success__label: {
      color: theme.palette.success.main,
      fontWeight: 400,
    },
    controlLabel: {
      lineHeight: "100%",
      fontWeight: "500",
    },
  
    root: {
      border: "0.5px solid",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      display: "flex",
      flex: "wrap",
      fontSize: "14px",
      height: "40px",
  
      "& .MuiButton-root": {
        borderRadius: "0px",
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        height: "39px",
      },
  
      "& hover": {
        borderColor: "#303E67",
      },
    },
  
    input: {
      display: "none",
    },
    filenameText: {
      padding: "5px",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    wrapper: {
      position: "relative",
      "& .MuiIconButton-root": {
        paddingLeft: "0px",
        fontSize: "16px",
        borderRadius: 0,
        padding: "0px",
        margin: "7px",
        lineHeight: "100%",
        fontWeight: 500,
        "&:hover": {
          outline: 0,
          border: 0,
          background: "transparent",
        },
      },
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }));
  
  const CompanyLogoUpload = (props) => {
    const MAX_SIZE = 2 * (1024 * 1024);
       //file types
       const acceptedType = ".png, .jpeg";
    const classes = useStyles();
  
    const {
      name,
      onFileSubmit = () => {},
      onFileRemove = () => {},
      fileName,
      filePath,
      accountId,
      userName,
      label,
    } = props;
 
    //file upload isSubmitted is used to display info message
    const [isProgress, setIsProgress] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [message, setMessage] = useState("");
    const inputRef = useRef();
    const [submittedFile, setSubmittedFile] = useState("");
    useEffect(() => {
      
      if (fileName != "") {
       
        setSubmittedFile(fileName);
      }
      return () => {
        setSubmittedFile("");
      };
    }, [fileName]);
  
    function openFileDialog(name) {
      name && document.getElementById(name).click();
    }
    const fileChangeHandler = (e) => {
    setMessage("");
  
      setSelectedFile(e?.target?.files[0]);
    };
  
    const bytesToMegaBytes = (bytes, roundTo) =>
      roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);
  
    //handles form submission
    const handleSubmission = (e) => {
      e.preventDefault();
       if(userName.trim()===""){
          setSelectedFile("");
          inputRef.current.value = "";
          return setMessage("Please Provide User Details First");
        }
      else if (selectedFile === "") {
        return setMessage("Please Select A File");
      } else if (bytesToMegaBytes(selectedFile.size > MAX_SIZE)) {
        setMessage(`File Size Can't Exceed ${bytesToMegaBytes(MAX_SIZE)}MB`);
      } else {
        // setMessage("loading......");
        setIsProgress(true);
        let formdata = new FormData();
        formdata.append("file", selectedFile);
  
        fileApi
          .uploadSubscriberLogo(formdata,userName)
          .then((response) => {
            setIsSubmitted(true);
  
            onFileSubmit(response.data);
            setSubmittedFile(response?.data?.fileName);
            setIsProgress(false);
            setMessage(response.data.message);
            setSelectedFile("");
            inputRef.current.value = "";
        
          })
          .catch(function (error) {
            setIsSubmitted(false);
            setIsProgress(false);
            setSelectedFile("");
            inputRef.current.value = "";
            if (error.response) {
              if (error.response.status === 500) {
                setMessage("Something Went Wrong");
              } else {
                setMessage(error.response.data["message"]);
              }
            } else if (error.request) {
              setMessage(error.request);
            } else {
              setMessage("Something Went Wrong");
            }
          });
      }
    };
    //remove files from list of files
    const removeFile = async () => {
      if (submittedFile) {
        
        try {
          setIsDeleting(true);
          const response = await fileApi.removeSubscriberLogo({fileName:fileName, uploadedFilePath:filePath, accountId:accountId?accountId:""});
          const responseData = await response.data;
  
          setMessage(responseData.message);
          setSubmittedFile("");
          setSelectedFile("");
          setIsSubmitted(false);
          onFileRemove();
          setIsDeleting(false);
          inputRef.current.value = "";
        } catch (error) {
          setMessage(!error.response ? "Server Not responding!": error?.response?.message ? error.response.message : "Something Went Wrong");
          setIsSubmitted(false);
          setSelectedFile("");
          setIsDeleting(false);
          inputRef.current.value = "";
        }
      }
    };
    return (
      <Paper elevation={0}>
        <div>
          <FormControl component="fieldset">
            <Typography
              className={classes.controlLabel}
              component={"label"}
              htmlFor={"contained-button-file"}
              variant="subtitle1"
            >
              {label}(Max File Size {bytesToMegaBytes(MAX_SIZE)}Mb){" "}
            </Typography>
  
            <div className={`formControl ${classes.root}`}>
              <input
                ref={inputRef}
                accept={acceptedType}
                className={classes.input}
                id={name}
                type="file"
                onChange={fileChangeHandler}
              />
  
              <Button
                variant="contained"
                color="primary"
                component="span"
                label={"Browse"}
                onClick={() => {
                  openFileDialog(name);
                }}
              ></Button>
  
              <span className={classes.filenameText}>
             
                {submittedFile != ""
                  ? submittedFile
                  : selectedFile?.name
                  ?selectedFile?.name
                  : "No File Choosen"
                  }
              </span>
              {filePath !== "" ? (
                <>
                  <FileDownload
                    apiString={fileApi.fileDownload}
                    filePath={filePath}
                    isIconBtn={true}
                  />
                   <div className={classes.wrapper}>
                  <IconButton className={classes.removeBtn} onClick={removeFile}
                   isProgress={isDeleting}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {isDeleting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                  </div>
                </>
              ) : (
                <Button
                  label="Upload File"
                  onClick={handleSubmission}
                  variant="contained"
                  isProgress={isProgress}
                  style={{
                    borderRadius: "0px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                />
              )}
            </div>
          </FormControl>
          {message ? (
            <span
              className={
                isSubmitted ? classes.success__label : classes.error__label
              }
            >
              {message}
            </span>
          ) : (
            `Only ${acceptedType} File Accepted`
          )}
        </div>
      </Paper>
    );
  };
  CompanyLogoUpload.propTypes={
    name:PropTypes.string.isRequired,
    onFileSubmit:PropTypes.func.isRequired,
    onFileRemove:PropTypes.func.isRequired,
    fileName:PropTypes.string.isRequired,
    filePath:PropTypes.string.isRequired,
    accountId:PropTypes.string,
    userName:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired,
    };
  export default CompanyLogoUpload;
  