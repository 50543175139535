//<summary>
//Used To show Alert on Form
// message is a string used to display message
//open is a bool state used for show/hide value
//setOpen is a function for close alert state change
//type is severity of Material UI alert component must be one of error, info, success or warning.
//</summary
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiAlert-icon":{
      padding:"0px",
    },
    "& .MuiAlert-message":{
      padding:"0px",
    },
    "& .MuiAlert-action":{
      alignItems: "baseline",
    },
  },
}));
export default function FormAlerts({ message, open, setOpen, type }) {
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={type || "info"}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
}
FormAlerts.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["error", "info", "success", "warning"]),
};
