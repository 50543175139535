import { makeStyles, FormGroup  } from '@material-ui/core';
import React from 'react';
const useStyles=makeStyles((theme)=>(
    {
root:{
  display:'flex',
  alignItems:"center",
  '& button':{
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0,
    height:'40px',
     //   '& button':{
  //     height:'40px',
  // },
  },
  '& .formControl .MuiInputBase-root':{
borderTopRightRadius:0,
borderBottomRightRadius:0,
  }
}
    }
))
const StyledFormGroup = ({children}) => {
  const classes=useStyles();
  return <FormGroup row  className={classes.root}>
{children}
</FormGroup>  ;
};

export default StyledFormGroup;
