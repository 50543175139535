import { Grid } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form';
import TextField from '../../controls/Input/TextField';
import AutoComplete from '../../controls/Select/AutoComplete';
import CompanyLogoUpload from './CompanyLogoUpload';
const SubscriberBasicInfoForm = ({useFormContext, salutations, isSalutationLoading, methods, onFileRemove,onFileSubmit, onExistanceValidation=()=>{}}) => {

    const {
        control,
        formState: { errors },
        
      } = useFormContext();
     

  return (
 <Grid container spacing={2}>
  <Grid item sm={6} xs={12} >
  <Controller
        name="companyName"
        control={control}
        rules={{
    required:"Company Name Required",
        // pattern:{value:/^[a-zA-Z0-9\s.&()\-`_\"]*$/, message:"Please Provide Valid Company Name, only Alphanumeric and special characters & - _( )  ` Allowed"},
     maxLength:{value:100, message:"Maximum 100 characters, Company Name allowed"},
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="Company Name"
        placeHolder="Company Name"
        isrequiredLabel={"true"}
        errors={errors?.companyName}
        helperText={errors.companyName?.message}
        />
      )}
        />
  </Grid>
  <Grid item sm={6} xs={12}>
  <Controller
      name={"title"}
      control={control}
      rules={{required:"Title Required"}}
      render={({ field: { onChange, value, name, onBlur } }) => (
        <AutoComplete
                    prompt={"Title"}
                    name={name}
                
                   options={salutations}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterSelectedOptions
                    onChange={(event, item) => onChange(item)}
                    value={value}
                    isRequiredLable={true}
                    errors={errors?.title}
                    helperText={errors.title?.message}
                    placeHolder="Select Ttile"
                    onBlur={onBlur}
                   isLoading={isSalutationLoading}
                  />
      )}
        />
  </Grid>
    <Grid item sm={6} xs={12}>
   
    
    <Controller
        name="firstName"
        control={control}
        rules={{required:"First Name Required",
        // pattern:{value:/^[a-zA-Z\s]*$/, message:"Please Provide Valid First Name, Only Alphabets Allowed"},
     maxLength:{value:100, message:"Maximum 100 characters, first name allowed"},
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="First Name"
        placeHolder="User First Name"
        isrequiredLabel={"true"}
        errors={errors?.firstName}
        helperText={errors.firstName?.message}
        />
      )}
        />
      
    </Grid>
    <Grid item sm={6} xs={12}>
    <Controller
        name="lastName"
        control={control}
        rules={{required:"Last Name Required",
        // pattern:{value:/^[a-zA-Z\s]*$/, message:"Please Provide Valid Last Name, Only Alphabets Allowed"},
     maxLength:{value:100, message:"Maximum 100 characters, last name allowed"},
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="Last Name"
        placeHolder="User Last Name"
        isrequiredLabel={"true"}
        errors={errors?.lastName}
        helperText={errors.lastName?.message}
        />
      )}
        />
    </Grid>
    <Grid item sm={6} xs={12}>
    <Controller
        name="emailId"
        control={control}
        rules={{required:"E-Mail Id Required",
        pattern:{value:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message:"Please Provide Valid Email id"},
     maxLength:{value:100, message:"Maximum 100 characters allowed"},
     validate:(value=>onExistanceValidation(value, 'isMail'))
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="E-Mail Id"
        placeHolder="E-Mail Id"
        isrequiredLabel={"true"}
        errors={errors?.emailId}
        helperText={errors.emailId?.message}
        />
      )}
        />
    </Grid>
    <Grid item sm={6} xs={12}>
    <Controller
        name="mobileNumber"
        control={control}
        rules={{required:"Mobile Number Required",
        pattern:{value:/^[6-9]\d{9}$/, message:"Please Provide Valid Mobile Number, it Must be start with 9, 8, 7 or 6 Ex. 9999999999"},
        minLength:{value:10, message:"Please Provide Valid 10 Digit Mobile Number"},
        maxLength:{value:10, message:"Only 10 Digit Mobile Number allowed"},
        validate:(value=>onExistanceValidation(value, 'isMobile'))
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="Mobile Number"
        placeHolder="Mobile Number"
        isrequiredLabel={"true"}
        errors={errors?.mobileNumber}
        helperText={errors.mobileNumber?.message}
        />
      )}
        />
    </Grid>
    <Grid item sm={6} xs={12}>
      
            <CompanyLogoUpload
              label="Company Logo"
              name={"companyLogoFileName"}
              fileName={methods.watch("companyLogoFileName")}
              filePath={methods.watch("companyLogoPath")}
              onFileSubmit={onFileSubmit}
              onFileRemove={onFileRemove}
              accountId={methods.watch("id")}
              userName={ methods.watch("companyName")}
              
            />
          </Grid>
   
 </Grid>
  )
}

export default SubscriberBasicInfoForm