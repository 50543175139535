import { Typography } from '@material-ui/core'
import React from 'react'
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
const useStyles=makeStyles((theme)=>({
    root:{

textAlign:'center',
paddingTop:"40px",
paddingBottom:"40px",
'& h4':{
    fontWeight:500,

}
    },
    icon:{
fontSize:'2.5rem',
color:theme.palette.info.main,
'@media (min-width:1281px)': {
    fontSize: '3rem',
  },
    }
}));
const NoData = ({message}) => {
    const classes=useStyles();
    return (
        <div className={classes.root}>
            <InfoIcon fontSize='medium' className={classes.icon}/>
            <Typography component={'h4'} variant='h4'>
               {message ? message: "No Records Available" }
            </Typography>
        </div>
    )
}

export default NoData
