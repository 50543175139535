import axios from "axios";
import { refresh } from "../Request/authApi";
import { getAccessToken, setAccessToken } from "../../accessToken";
export const excelService = axios.create({
   baseURL:process.env.REACT_APP_EXCEL_SERVICE_URL,
headers: {'Ocp-Apim-Subscription-Key':process.env.REACT_APP_SUBSCRIPTION_KEY,
'Ocp-Apim-Trace':true},
  withCredentials: false,
});
excelService.interceptors.request.use(
  (request) => {
    request.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
excelService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;

  if (error?.response.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await refresh(getAccessToken());
      setAccessToken(newAccessToken.data);
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken.data}`;
      return excelService(prevRequest);
    }
   else if(!error?.response){
      error.response.data.message="Internal Server Error!";
      
     }
     else if(error?.response.status>=500){
      error.response.data="Internal Server Error!"
    }
     else if(typeof (error?.response?.data?.message) !=="string"){
      error="Internal Server Error";
     }
    return Promise.reject(error);
  }
);
