//#region imports
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Card, Typography, makeStyles, Button } from '@material-ui/core'
import AnimationFade from '../Animation/AnimationFade';
//#endregion imports
//#region Component Level Style
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#edf0f5'

    },
    pageHeader:{
        padding:"6px 0px",
        display:'flex',
       [theme.breakpoints.down('xs')]:{
           display:'block',
       },
        alignItems:'center',
    },
    pageIcon:{
        display:'inline-block',
        padding:theme.spacing(2),
        color:'#3c44b1'
    },
    pageTitle:{
        textAlign:'left',
        //paddingLeft:theme.spacing(1),
       
        '& .MuiTypography-h2':{
            color:theme.palette.text.primary,
           fontSize:'24px',
            fontWeight:600,
            [theme.breakpoints.down('md')]: {
                fontSize: '16px',
              },
             
        },
        '& .MuiTypography-subtitle2':{
            opacity:'0.6',
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
              },
        }
    },
    pageInfo:{
        display:'flex',
        color:'#3c44b1',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent:'flex-end',
        '& .MuiGrid-container':{
             justifyContent:'flex-end',
             [theme.breakpoints.down('xs')]:{
                 justifyContent:'flex-start',
             },

        },
    },
}))
//#endregion  Component Level Style
const PageHeader = (props) => {
    const classes = useStyles();
    const { title, subTitle, icon, children } = props;
    return (
      <AnimationFade>
          <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
                {icon && <Card className={classes.pageIcon}>
                    {icon}
                </Card>
                }
               
                <div className={classes.pageTitle}>
                    <Typography
                        variant="h2"
                        component="h2">
                        {title}</Typography>
                    <Typography
                        variant="subtitle2"
                        component="p">
                        {subTitle}</Typography>
                </div>
                <div className={classes.pageInfo}>
                    {children}
                    </div>
            </div>
        </Paper>
      </AnimationFade>
    )
};
PageHeader.propTypes={
    title:PropTypes.string,
    subTitle:PropTypes.string,
    icon:PropTypes.any,
    children:PropTypes.node
};
export default PageHeader
