import React from 'react';
import { useLocation,Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
const AuthRequire = ({allowedRoles=[], allowedPermissions=[]}) => {
    const {auth}=useAuth();
    const location=useLocation();
    const hasRoles=allowedRoles?.length>0;
   const permittedRoles=auth?.roles?.find(role=>allowedRoles?.includes(role));
  
  return (
   (hasRoles && permittedRoles)
    ? <Outlet/>
     :auth?.userName
    ?<Navigate to="/unauthorised" state={{ from:location}} replace/>
    :<Navigate to="/login" state={{ from:location}} replace/>
      );
};

export default AuthRequire;
