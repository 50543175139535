import React from 'react'
import { Card as MuiCard, makeStyles } from '@material-ui/core'

const useStyles=makeStyles({
    root:{
        justifyItems:'center',
        
    }
});
const Card = (props) => {
const classes=useStyles();
    const {children, cssStyle}=props;
    return (
        <MuiCard variant='outlined' className={classes.root +" "+ (cssStyle || '')}>
            {children}
        </MuiCard>
    )
}

export default Card
