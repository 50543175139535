import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button } from "../../controls";
import useColumns from "./useColumns";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    width: "100%",
  },
}));
const ColumnsFilter = ({ handleHeaders, ColumnlHeaders, tableId }) => {
  const classes = useStyles();
  const [tableHiddenCol, setTableHiddenCol] = useState([]);
  useEffect(() => {
    const hiddenColumns = JSON.parse(localStorage.getItem(tableId));
    const headers = [];
    for (const key in ColumnlHeaders) {
      headers.push({
        id: ColumnlHeaders[key].id,
        accessor: ColumnlHeaders[key].accessor,
        label: ColumnlHeaders[key].label,
        disableSorting: ColumnlHeaders[key].disableSorting,
        disableFilter: ColumnlHeaders[key].disableFilter,
        align: ColumnlHeaders[key].align,
        width: ColumnlHeaders[key].width,
        show: hiddenColumns?.includes(ColumnlHeaders[key].accessor)
          ? false
          : ColumnlHeaders[key].show,
      });
    }
    setTableHiddenCol(headers);

    return () => {
      setTableHiddenCol([]);
    };
  }, [ColumnlHeaders]);

  const onColumHide = (event) => {
    const { name, checked } = event.target;

    const hiddenColumns = JSON.parse(localStorage.getItem(tableId));
    const headers = [];
    for (const key in tableHiddenCol) {
      headers.push({
        id: tableHiddenCol[key].id,
        accessor: tableHiddenCol[key].accessor,
        label: tableHiddenCol[key].label,
        disableSorting: tableHiddenCol[key].disableSorting,
        disableFilter: tableHiddenCol[key].disableFilter,
        align: tableHiddenCol[key].align,
        width: tableHiddenCol[key].width,
        show:
          tableHiddenCol[key].accessor === name
            ? checked
            : tableHiddenCol[key].show,
      });
    }
    setTableHiddenCol(headers);
    handleHeaders(headers);
    if (hiddenColumns !== null) {
      if (hiddenColumns.includes(name)===false) {
        let hiddenV = [];
        hiddenV = [...hiddenColumns, name];
        localStorage.setItem(tableId, JSON.stringify(hiddenV));
      } else if (hiddenColumns.includes(name)) {
       if(checked){
        let filterdCols = [];
        filterdCols = hiddenColumns.filter((c) => c !== name);
        if (filterdCols?.length <= 0) {
          localStorage.removeItem(tableId);
        } else {
          localStorage.setItem(tableId, JSON.stringify(filterdCols));
        }
       }
      }
    } else {
      const hiddenV = [name];
      localStorage.setItem(tableId, JSON.stringify(hiddenV));
    }
  };
  const onShowAllColumn = () => {
    const headers = [];
    for (const key in tableHiddenCol) {
      headers.push({
        id: tableHiddenCol[key].id,
        accessor: tableHiddenCol[key].accessor,
        label: tableHiddenCol[key].label,
        disableSorting: tableHiddenCol[key].disableSorting,
        disableFilter: tableHiddenCol[key].disableFilter,
        align: tableHiddenCol[key].align,
        width: tableHiddenCol[key].width,
        show: true,
      });
    }
    setTableHiddenCol(headers);
    handleHeaders(headers);
    localStorage.removeItem(tableId);
  };
  const onHideAllColumn = () => {
    const headers = [];
    for (const key in tableHiddenCol) {
      headers.push({
        id: tableHiddenCol[key].id,
        accessor: tableHiddenCol[key].accessor,
        label: tableHiddenCol[key].label,
        disableSorting: tableHiddenCol[key].disableSorting,
        disableFilter: tableHiddenCol[key].disableFilter,
        align: tableHiddenCol[key].align,
        width: tableHiddenCol[key].width,
        show: key > 0 ? false : true,
      });
    }
    let hiddenCol = [];
    headers.forEach((col, index) => {
      if (index > 0) {
        hiddenCol = [...hiddenCol, col.accessor];
      }
    });
    localStorage.setItem(tableId, JSON.stringify(hiddenCol));
    setTableHiddenCol(headers);
    handleHeaders(headers);
  };
  return (
    <Paper className={classes.root}>
      <div
        style={{
          height: "240px",
          width: "100%",
          display: "block",
          overflowY: "scroll",
        }}
      >
        <FormGroup>
          {tableHiddenCol?.map(
            (item, index) =>
              index > 0 && (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={item.show}
                      color="primary"
                      name={item.accessor}
                      onChange={(e) => {
                        onColumHide(e);
                      }}
                    />
                  }
                  label={item.label}
                />
              )
          )}
        </FormGroup>
      </div>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="text" label="Hide All" onClick={onHideAllColumn} />
        <Button
          variant="text"
          label="Show All"
          onClick={onShowAllColumn}
        ></Button>
      </div>
    </Paper>
  );
};

export default ColumnsFilter;
