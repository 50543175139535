import { Paper } from '@material-ui/core';
import React from 'react'
import { useParams } from 'react-router-dom';
import AnimationFade from '../components/Animation/AnimationFade';
import SubscriberDetails from '../components/Subscribers/SubscriberDetails';
import SubscribersHeader from '../components/Subscribers/SubscribersHeader';
const SubscriberDetailsPage = () => {
//#region navigation and uri param
//uri param
const { subscriberId } = useParams();
const subTitle="View Subscriber Details";
//#endregion
  return (
    <AnimationFade>
   <SubscribersHeader subTitle={subTitle}/>
   <Paper>
  
        <SubscriberDetails subscriberId={subscriberId}/>
 
   </Paper>
   </AnimationFade>
  )
}

export default SubscriberDetailsPage