import { AnimatePresence } from 'framer-motion';
import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AuthRequire from '../components/Authentication/AuthRequire';
import SubscriberDetailsPage from '../pages/SubscriberDetailsPage';
import SubscriberPage from '../pages/SubscriberPage';
import SubscribersPage from '../pages/SubscribersPage';

const SuperAdminRoutes = () => {
    const location=useLocation();
    
  return (
    <AnimatePresence exitBeforeEnter>
    <Routes key={location.key} location={location}>
      <Route element={<AuthRequire allowedRoles={["SuperAdmin"]}/>}>

      <Route path='/' element={<SubscribersPage />}> </Route>
       <Route path="subscriber" element={<SubscriberPage/>}></Route>
        <Route path='subscriber/:subscriberId' element={<SubscriberPage/>}></Route>
        <Route path='details/:subscriberId' element={<SubscriberDetailsPage/>}></Route>
      <Route path="*" element={<Navigate replace to="/pageNotFound" />} />
     
      </Route>
    </Routes>
   </AnimatePresence>
  )
}

export default SuperAdminRoutes