import React, { useEffect, useState } from "react";
import FormHeader from "../../controls/Form/FormHeader";
import * as usersApi from "../../services/Api/Request/usersApi";
import FullPageLoader from "../Loader/FullPageLoader";
import NoData from "../NoData/NoData";
import SubscriberDetailsForm from "./SubscriberDetailsForm";
const SubscriberDetails = ({ subscriberId }) => {
  const [data, setData] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const controller = new AbortController();
    const getDetails = async () => {
      try {
        setIsFormLoading(true);
        const response = await usersApi.getSubscriberDetails(
          subscriberId,
          controller?.signal
        );
        const responseData = await response.data;
        setData(responseData);
        setIsFormLoading(false);
      } catch (error) {
        setError(
          error?.response?.data ? error.response.data : "Something went Wrong"
        );
        setIsFormLoading(false);
      }
    };
    subscriberId && getDetails();
    return () => {
      setData("");
      controller.abort();
    };
  }, [subscriberId]);

  return (
    <>
      {isFormLoading && <FullPageLoader />}
      <FormHeader title={"Subscriber Details"}/>
      {error !== "" ? (
        <NoData message={error} />
      ) : (
        <SubscriberDetailsForm data={data} />
      )}
    </>
  );
};

export default SubscriberDetails;
