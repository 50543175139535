import { Popover } from '@material-ui/core';
import React from 'react'
import ColumnsFilter from './ColumnsFilter';


const TableColumnFilterMenu = ({anchorEl, handleClose, tableId,handleHeaders, ColumnlHeaders}) => {
    //filter
    const open = Boolean(anchorEl);
    const id = open ? tableId : undefined;
  return (
    <Popover
    id={id}
    open={open}
 //   className={classes.root}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
 <ColumnsFilter handleHeaders={handleHeaders} ColumnlHeaders={ColumnlHeaders} tableId={tableId}/>
  </Popover>
  )
}

export default TableColumnFilterMenu