//#region summary
//Scroolable Modal popup
//#endregion
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
 
  Divider,
  Paper,
} from "@material-ui/core";
import ActionButton from "../Button/ActionButton";
import CloseIcon from "@material-ui/icons/Close";
import AnimationFade from "../../components/Animation/AnimationFade";
import Draggable from "react-draggable";
//#region styling
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    paddingTop: "0px ",
    position: "absolute",
    top: theme.spacing(1),
    display: "flex",
    minWidth: "280px",

  },
  dialogTitle: {
    background: "#fff",
    color: theme.palette.text.primary,
    padding: "15px 12px 12px 12px",
    textTransform: "upperCase",
  },
  dialogContent:{
    padding: "8px",
  },
}));
//#endregion

export default function Popup(props) {
  const { title, children,maxWidth, openPopup, setOpenPopup,showCloseBtn=true, fullWidth, ...others } =
    props;
  const classes = useStyles();
  //generate unique id for dialog , exclude special char from title
  const titleId=  title?.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_');
  const dialogId=titleId? titleId:"dragable_id";
   function PaperComponent(props) {
    return (
      <Draggable handle={`#${dialogId}`} cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }
  return (
    <AnimationFade>
    <Dialog
      open={openPopup}
      maxWidth={maxWidth || 'sm'}
      fullWidth={true}
      fullScreen={false}
      classes={{ paper: classes.dialogWrapper }}
      PaperComponent={PaperComponent}
      aria-labelledby={dialogId}
    >
      <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id={dialogId}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" component="h6" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
         { showCloseBtn && <ActionButton
            style={{ color: "#303E67", margin: "0px", border: "none" }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </ActionButton>}
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
    </Dialog>
      </AnimationFade>
  );
}
