import React from "react";
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../Button/Button";
import UploadPng from "../../assets/images/upload.png";
import deletePng from "../../assets/images/delete-icon-red.svg";
import checkmarkPng from "../../assets/images/check-mark.png";
const useStyles = makeStyles((theme) => ({
  upload__Form: {
    textAlign: "center",
    maxWidth: "700px",
    margin: "0 auto",
    paddingTop: "30px",
    paddingBottom: "30px",
    "& h2": {
      color: "#303e67",
      textTransform:"uppercase",
    },
  },
  outerSection: {
    minHeight: "300px",
    borderRadius: "5px",
    border: "1px solid #e3e3e3",
    marginTop: "10px",
    marginBottom: "10px",
  },
  middleSection: {
    display: "flex",
    justifyContent: "center",
    minHeight: "300px",
    alignItems: "center",
    margin: "15px",
    background: "#f9f9f9",
    borderRadius: "5px",
    border: "2px dashed #e3e3e3",
    flexDirection: "column",
    position:'relative',
    "& input": {
      position: "absolute",
      // left: 0,
      // top: 0,
      height: "100%",
      width: "100%",
      opacity: 0,
      cursor: "pointer",
    },
    "& .figure img": {
      maxWidth: "70px",
    },

    "& p": {
      color: "#8c93ad",
      margin: 0,
      marginTop: "10px",
      marginBottom: "6px",
    },
  },
  message: {
    "& p": {
      //background: "#f5fbee",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "16px",
      //color: "#6ab13e",
      color: theme.palette.error.main,
      fontWeight:"700",
      marginBottom: "10px",
      textTransform:"capitalize",
    },
  },

  dragfile: {
    margin: 0,
    position: "relative",

    

    "& img": {
      pointerEvents: "none",
    },
  },

  customFile__input: {
    color: "transparent",
    position: "relative",
    width: "138px",
    "& input": {
      position: "absolute",
      left: 0,
      top: 0,
      width: "138px",
      height: "47px",
      opacity: 0,
      cursor: "pointer",
    },

    "& ::-webkit-file-upload-button": {
      visibility: "hidden",
    },

    "& ::before": {
      content: "Select file",
      color: "#1761fd",
      display: "inline-block",
      border: "1px solid #1761fd",
      borderRadius: "3px",
      padding: "10px 30px",
      outline: "none",
      whiteSpace: "nowrap",
      "-webkit-user-select": "none",
      pointerEvents: "none",
      margin: "0 auto",
      fontFamily: "poppins",
    },
    "& :active": {
      outline: 0,
    },
    "& :active::before": {
      background: "-webkit-linear-gradient(top, #e3e3e3, #f9f9f9)",
    },
  },
  upload__file: {
    display: "flex",
    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    marginTop: "2px",
    alignItems: "center",
    padding: "6px",
    marginBottom: "2px",

    "& p": {
      margin: 0,
      paddingLeft: "10px",
      fontSize: "14px",
      color: "#36446c",
    },
    "& p span": {
      color: "#b4bacf",
    },
    "& img": {
      maxHeight: "27px",
    },
  },
  delete__icon: {
    marginLeft: "auto",
    padding: "4px",
    border: "1px solid #e3e3e3",
    borderRadius: "4px",
    display: "inline-flex",
    width: "31px",
    height: "31px",
    "& .delete__icon img": {
      maxHeight: "20px",
    },
  },
  done__btn: {
    textDecoration: "none",
    background: "#1761fd",
    color: "#fff",
    fontSize: "12px",
    padding: "10px 30px",
    display: "flex",
    borderRadius: "5px",
    width: "100px",
    justifyContent: "center",
    margin: "auto",
    marginTop: "10px",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const UploadDrag =React.forwardRef((props, inputRef) => {
  const classes = useStyles();
  const {
    
    label,
    message,
    accept,
    value,
    isMulti,
    id,
    name,
    isSubmitted,
    onfileSelection,
    onFileRemove,
    onSubmitHandler,
  } = props;
 const onChangeHandler=(e)=>{
   onfileSelection(e);
  inputRef.current.value="";
 }
  const bytesToMegaBytes = (bytes, roundTo) =>
    roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024);
  return (
    <div className={classes.upload__Form}>
      <Typography variant="h6" component="h2">
        {label}
      </Typography>
      {message !=='' && (
        <div className={classes.message}>
          <Typography variant="subtitle1" component="p">
            {message}
          </Typography>
        </div>
      )}
      <div className={classes.outerSection}>
        <div className={classes.middleSection}>
        <input
           ref={inputRef}
              type="file"
              id={id || "uploadDocs"}
              name={name || "uploadDocs"}
              onChange={onChangeHandler}
              accept={accept || "*"}
              multiple={isMulti || false}
            />
          <figure className={classes.dragfile}>
           
            <img src={UploadPng} alt='upload' />
          </figure>
          <div variant="subtitle1" component="p">
            Drag and drop your files {accept ==="*"?"(All Type)" :`(${accept})`} here
            <Typography>or</Typography>
          </div>

          <div className={classes.customFile__input}>
       
            <label htmlFor="uploadDocs">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onSubmit={onfileSelection}
                label="Select File"
              >
                
              </Button>
            </label>
          </div>
        </div>
      </div>
      {value.length > 0
        ? value.map((item, index) => (
            <div className={classes.upload__file} key={index}>
              <img className={classes.check} src={checkmarkPng} alt='checkmark'/>
              <p>
                {item.name}

                <span>| {bytesToMegaBytes(item.size, 3)} MB</span>
              </p>

              <div className={classes.delete__icon} onClick={onFileRemove}>
                <img src={deletePng} alt='delete'/>
              </div>
            </div>
          ))
        : null}
 
     
      <div className={classes.wrapper}>

      <Button label="Upload" variant="contained" onClick={e=>{onSubmitHandler(e)}}
       disabled={isSubmitted}
      ></Button>
     {isSubmitted && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
});
UploadDrag.prototypes={
  label:PropTypes.string.isRequired,
  message:PropTypes.string,
  accept:PropTypes.string,
  value:PropTypes.any,
  isMulti:PropTypes.bool,
  id:PropTypes.string,
  name:PropTypes.string,
  isSubmitted:PropTypes.bool.isRequired,
  onfileSelection:PropTypes.func.isRequired,
  onFileRemove:PropTypes.func.isRequired,
  onSubmitHandler:PropTypes.func.isRequired,
};
export default UploadDrag;
