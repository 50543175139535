//#region summary
//create subscriber with admin role
//these subscriber works as admin of their comapny and can create masters of admin panel
//#endregion
import { Paper } from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from "react";
import {
  useForm,
  useFormContext,
  FormProvider,
} from "react-hook-form";
import { Notification } from "../../controls";
import FullPageLoader from "../Loader/FullPageLoader";
import HorizentalStepper from "../../controls/Stepper/HorizentalStepper";
import { useNavigate, useParams } from "react-router-dom";
import * as usersApi from "../../services/Api/Request/usersApi";
import SubscriberBasicInfoForm from "./SubscriberBasicInfoForm";
import SubscriberSignupForm from "./SubscriberSignupForm";
import FormAlerts from "../../controls/Notification/FormAlerts";
import SubscribersHeader from "./SubscribersHeader";
import AnimationFade from "../Animation/AnimationFade";
import { usePreventPrompt } from "../../hooks/usePreventPrompt";
import UnSavedPrompt from "../../controls/ConfirmDialog/UnSavedPrompt";
//#region initial form values
const initialFormValues = {
    id: "",
    companyName:"",
    title: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNumber: "",
    companyLogoPath: "",
    companyLogoFileName: "",
    orgCompanyLogoFileName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    isActive: true,  
   
  };
//#endregion
const Subscriber = ({subscriberId}) => {
 //#region naviagtion 
 //subscriberId ===get from props, if subscriberId available then component works for update
   //navigation
  const navigate = useNavigate();
 //#endregion
  //#region  react-hook-form 's useForm hook
  const methods = useForm({
    mode: "onTouched",
    defaultValues: initialFormValues,
  });
   //#endregion
const isDirty=methods.formState.isDirty;
const [isFormDirty, setIsFormDirty] = useState(isDirty);
const [showPrompt, confirmNavigation, cancelNavigation]=usePreventPrompt(isFormDirty);
useEffect(() => {
  setIsFormDirty(isDirty);

  return () => {
    setIsFormDirty(false);
  }
}, [isDirty])

  //#region states of component
  const [info, setInfo] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [salutations, setSalutations] = useState([]);
  const [isSalutationLoading, setIsSalutationLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  const [subscriberName, setSubscriberName] = useState("");
  //#endregion
  //#region  get all titles (salutation)
  useEffect(() => {
    const getSalutations = async () => {
      try {
        setIsSalutationLoading(true);
        const response = await usersApi.getTitles(controller?.signal);
        setSalutations(response.data);
        setIsSalutationLoading(false);
      } catch (error) {
        setInfo({
          isOpen: true,
          message: error?.response?.data
            ? error.response.data
            : "something Went wrong",
          type: "error",
        });

        setIsSalutationLoading(false);
      }
    };
    const controller = new AbortController();
    getSalutations();
    return () => {
      setSalutations([]);
      controller.abort();
    };
  }, []);
//#endregion
 //#region set Error Message generated by api error catch
 const handlesErrorMessage=(error)=>{
    const msg=!error?.response ? "Internal Server Error!" : error?.response?.data ? error?.response?.data:"Something Went Wrong!"; 
    setErrorMsg(msg);
  }
  //#endregion
   //#region  to close error message
   const handleIsOpen = () => {
    setErrorMsg("");
  };
  //#endregion  
//#region  get subscriber when subscriberId at pageload for get values for edit
  useEffect(() => {
    const getUserDetails = async (subscriberId) => {
      try {
        setIsFormLoading(true);
        const response = await usersApi.getSubscriberById(subscriberId, controller?.signal);
        const responseData = await response.data;
        methods.reset(responseData);
        setSubscriberName(responseData?.companyName ? responseData?.companyName :"");
        methods.setValue("confirmPassword", responseData.password);
        setIsFormLoading(false);
      } catch (error) {
       handlesErrorMessage(error);
        setIsFormLoading(false);
      }
    };
    const controller = new AbortController();
    subscriberId && getUserDetails(subscriberId);
    return () => {
      methods.reset();
      controller.abort();
    };
  }, [subscriberId]);
  //#endregion
  //#region logo Upload
  //on Profile Pic Upload
  const onFileSubmit = (profile) => {
    methods.setValue("companyLogoPath", profile.filepath);
    methods.setValue("companyLogoFileName", profile.fileName);
    methods.setValue("orgCompanyLogoFileName", profile.orgFileName);
  };
  //on Profile Pic Removed
  const onFileRemove = () => {
    methods.setValue("companyLogoFileName", "");
    methods.setValue("companyLogoPath", "");
    methods.setValue("orgCompanyLogoFileName", "");
  };
  //#endregion
//      //#region handles CheckExistance validation
//  const onExistanceValidation=useCallback(async(value, cntrl)=>{
//   try {
    
//  const qry={
//   userId:methods.getValues('id'),
//      emailId:cntrl ==='isMail' ?value :"",
//      mobileNumber:cntrl ==='isMobile' ?value :"",
//      userName:cntrl==='isUserName' ? value :"",
//  }
//  const response=await usersApi.checkExistance(qry);
//  return  !!(response.data);
// } catch (error) {
// let msg = (cntrl ==='isMail') ? 'Email Id Is Already In Use': (cntrl ==='isMobile') ? 'Mobile Number Is Already In Use' : 'User Name Is Already In Use'
// msg=error?.response?.data?.msg ? error?.response?.data :msg; 
// return  msg;
// }
// });
 //#endregion
  //#region  steps and their components

  const steps = [
    {
      id: 0,
      label: "Subscriber Basic Information",
      content: (
        <SubscriberBasicInfoForm
          useFormContext={useFormContext}
          salutations={salutations}
          isSalutationLoading={isSalutationLoading}
          methods={methods}
          onFileRemove={onFileRemove}
          onFileSubmit={onFileSubmit}
         // onExistanceValidation={onExistanceValidation}
        />
      ),
    },
    {
      id: 1,
      label: "Login Information",
      content: (
        <SubscriberSignupForm useFormContext={useFormContext} methods={methods}
        // onExistanceValidation={onExistanceValidation}
          />
      ),
    },
   ];
//#endregion
  
//#region  hanlde page submit
  const submitHandler = async (values) => {
    try {
      setIsFormLoading(true);
      const response = await usersApi.addSubscriber(values);
      const responseData = await response.data;
      setIsFormDirty(false);
      setInfo({
        isOpen: true,
        message: responseData?.message
          ? responseData.message
          : subscriberId
          ? "Subscriber Updated Successfully"
          : "Subscriber Created Successfully",
      });
      setTimeout(() => {
        navigate(`/subscribers`);
        methods.reset(initialFormValues);
        setIsFormLoading(false);
      }, 1000);
    } catch (error) {
        handlesErrorMessage(error);
      setIsFormLoading(false);
    
    }
  };
  //#endregion
  const subTitle=subscriberId ? (subscriberName!=="" ? `Manage ${subscriberName}`: 'Manage Subscriber' ): "Create New Subscriber"
  return (
    <>
        {isFormLoading && <FullPageLoader />}
        <AnimationFade>
  <SubscribersHeader subTitle={subTitle}/>
   </AnimationFade>
     <AnimationFade>
     <Paper>
        
        <HorizentalStepper
          stepValues={steps}
          onSubmit={submitHandler}
          FormProvider={FormProvider}
          methods={methods}
          ErrorView={<FormAlerts message={errorMsg} open={errorMsg!=""} setOpen={handleIsOpen} type="error" />}
         />
      </Paper>
     </AnimationFade>
      {info.isOpen && <Notification {...{ info, setInfo }} />}
      {<UnSavedPrompt
      showDialog={showPrompt}
      confirmNavigation={confirmNavigation}
      cancelNavigation={cancelNavigation}
    />}
    </>
  );
};
Subscriber.propTypes={
subscriberId:PropTypes.string,//optional
};
export default Subscriber;
