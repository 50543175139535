
import {  MuiThemeProvider } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import './App.css';
import NavigationRoutes from './routes/NavigationRoutes';
import {  responsiveFontSizes } from '@material-ui/core/styles';
import Theme from './theme/theme';
import ErrorBoundary from './components/Error/ErrorBoundry';
import  "./services/accessToken";
import ConnectionStatus from './components/HealthCheck/ConnectionStatus';
import Maintenance from './components/UnderMaintenance/Maintenance';


function App() {
let theme= Theme;
theme=responsiveFontSizes(theme);
const isUnderMaintenance=process.env.REACT_APP_IS_UNDER_MAINTENANCE;
  return (
    <MuiThemeProvider theme={theme}>
    <CssBaseline/>
    <ErrorBoundary>

    <ConnectionStatus/>
    {isUnderMaintenance==="true" ? <Maintenance/> :
       <NavigationRoutes/>
  }
    </ErrorBoundary>
    
     
    </MuiThemeProvider>
  );
}

export default App;
