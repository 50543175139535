import { TableSortLabel, TableCell, TableRow, makeStyles, TableHead, Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from "@material-ui/icons/FilterList";
const useStyles = makeStyles((theme) => ({
  root: {
    
    fontSize: 15,
    fontWeight: "600",
    // '& .MuiTableCell-head':{
    //   color: theme.palette.secondary.main,
    //   backgroundColor: theme.palette.background.primary,//"#89d9ffde",

    // },
    '& .MuiTableCell-sizeSmall':{
      padding:"10px 2px 10px 8px",
   

        },
    '& .MuiButton-root':{
      paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '5px',

        },
        '& .MuiButton-root:hover':{
background:'none',
        },
  },


  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const SortedTableHead = ({Header, order, orderBy,createSortHandler, showMenu=true,handleFilter}) => {
  const classes=useStyles();

  const onCreateSortHandler=(accessor,e)=>{
    createSortHandler(accessor,e)
  }
  return (
    
    <TableHead className={classes.root}>
    <TableRow>
      {Header?.map((headCell) => (
        (typeof (headCell?.show) !='undefined' ? headCell.show :true) &&
        <TableCell
          size="small"
          key={headCell.id}
          align={headCell.align}
          sortDirection={orderBy === headCell.accessor ? order : false}
          style={{ minWidth: headCell.width, maxWidth:headCell?.maxWidth ? headCell?.maxWidth :headCell?.width }}
        >
          
          {headCell.disableSorting ? (
            headCell.label
          ) : (
        
            <TableSortLabel
              active={orderBy === headCell.accessor}
              direction={orderBy === headCell.accessor ? order : "asc"}
              onClick={(e) => onCreateSortHandler(headCell.accessor, e)}
            >
              {headCell.label}
              {orderBy === headCell.accessor ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          
          )}

{(headCell.id===1 && showMenu) ? (
                <Button
                  startIcon={<FilterListIcon />}
                  onClick={handleFilter}
                  label={headCell.id}
                  style={{paddingLeft:"4px",paddingRight:"2px"}}
                />
              ) : (
                ""
              )}
        </TableCell>
      ))
      }
    
    </TableRow>
  </TableHead>
  )
};
SortedTableHead.propTypes={
  Header: PropTypes.array,
  order:PropTypes.string,
  orderBy:PropTypes.string,
  createSortHandler: PropTypes.func
}
export default SortedTableHead