import React from 'react';
import { useParams } from 'react-router-dom';
import Subscriber from '../components/Subscribers/Subscriber';
const SubscriberPage = () => {
//#region  uri param

 const { subscriberId } = useParams();
 
 //#endregion
 return (
         
            
   <Subscriber subscriberId={subscriberId}/>
        
  
 )
}

export default SubscriberPage