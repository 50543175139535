import { createTheme } from "@material-ui/core";
const primacyColor = { background: "#edf0f5", color: "#303e67" };
const secondaryColor = { background: "#fafafc", color: "#00000" };
const LightTheme = createTheme({
  palette: {
    background: {
      default: primacyColor.background,
    },
    text: {
      primary: "#303e67",
      secondary: "#00000",
    },
    common: {
      default: {
        color: "#535e81",
        backgroundColor: "#fafafc",
      },
      gray: {
        backgroundColor: {
          backgroundColor: "#fafafc",
        },
        color: {
          color: primacyColor.color,
        },
      },
    },
    default: {
      main: "#000",
    },
    primary: {
      main: "#1761fd",
      dark: "#3d79f7",
      //dark:"#303E67",
    },
    secondary: {
      main: "#fff",
    },
    success: {
      main: "#5caa2d",
    },
    error: {
      main: "#f44336",
    },
    border: {
      main: "#dadde1",
    },
    borderLine: {
      main: "#1761fd",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Poppins", "sans-serif"].join(","),

    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },

  overrides: {
    MuiTypography: {
      h2: {
        fontWeight: 500,
        "@media (max-width:1280px)": {
          fontSize: "18px",
        },

        "@media (min-width:1280px)": {
          fontSize: "22px",
        },
        "@media (max-width:600px)": {
          fontSize: "16px",
        },
        "@media (min-width:600px)": {
          fontSize: "16px",
        },
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"],
        fontWeight: 500,
        maxWidth: "-webkit-fill-available",
        fontSize: "16px",
        "@media (min-width:1280px)": {
          fontSize: "16px",
        },
        "@media (max-width:1280px)": {
          fontSize: "14px",
        },

        "@media (max-width:600px)": {
          fontSize: "14px",
        },
        "@media (min-width:600px)": {
          fontSize: "12px",
        },
      },

      h6: {
        fontFamily: ["Poppins", "sans-serif"],
        fontSize: "14px",
        "@media (min-width:1280px)": {
          fontSize: "16px",
        },
        "@media (max-width:1280px)": {
          fontSize: "14px",
        },

        "@media (max-width:600px)": {
          fontSize: "12px",
        },
        "@media (min-width:600px)": {
          fontSize: "12px",
        },
      },
      subtitle1: {
        fontSize: "14px",
      },
    },
    MuiButton: {
      root: {
        padding: "5px 5px",
        lineHeight: "1.2",
        color: "#1761fd",
        minWidth: "50px",
      },
       containedPrimary:{
        border: '1px solid #1761fd',
        '& .Mui-disabled':{
          border: '1px solid #d0d3d7',
        },
      },
    
      label: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px",
        // color:"#1761fd",
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&:hover fieldset": {
            border: "1.5px solid",
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&.Mui-focused fieldset": {
            border: "1.5px solid",
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
      },
    },

    MuiFormControl: {
      root: { width: "100%" },
    },
    MuiFormHelperText: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: "none",
      },
    },
    MuiTable: {
      root: {
        minWidth: "800px",
      },
    },
    MuiTableHead: {
      root: {
        // backgroundColor: "#fafafc",
        // color: "#303e67",
      },
    },

    MuiTableRow: {
      root: {
        verticalAlign: "baseline",
        backgroundColor: "#fff",
        // "&:nth-of-type(2n+2)": {
        //   backgroundColor: "#fafafa",
        // },
        "&$selected": {
          backgroundColor: "#e4e7eb",
        },
      },
      hover: {
        "&:hover": {
          backgroundColor: "rgba(237,240,245,1) !important",
        },
      },

      head: {
        backgroundColor: "#fafafc",
        color: "#03e67",
        borderTop: "1px solid #ebecef",
        // borderTopColor:'dadde1',
        borderBottom: "1px solid #ebecef",
        //borderBottomColor:'dadde1',
        fontWeight: "500",
        lineHeight: "100%",
        '& .MuiTableCell-sizeSmall':{
          padding:"10px 2px 10px 8px",
         },
      },
    },
    MuiTableCell: {
      root: {
        border: "0px",
        borderBottom: "none",
        padding: "3px 2px 4px 8px",
        // fontSize: 15,
        // fontWeight:'600',
      },
      sizeSmall: {
        padding: "3px 2px 4px 8px",
      },
      head: {
        // color: "#5f6985",
        // fontWeight: 500,
        //lineHeight: "100%",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#fff",
        marginRight: "5px",
        marginBottom: "5px",
        borderRadius: "5px",
        minWidth: "50px !important",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#f4f8ff",

        margin: "5px",
      },
    },
    MuiTreeView: {
      root: {
        "& :hover": {
          backgroundColor: "transparent",
        },
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
      },
    },
    MuiAccordion:{
      root:{
        border: '1px solid rgba(0, 0, 0, .125)',
    //borderTop: `2px solid #1761fd`,
      },
    },
    MuiAccordionSummary:{
      root:{
    
       backgroundColor: 'rgba(0, 0, 0, .03)',
       borderBottom: '1px solid rgba(0, 0, 0, .125)',
       },
   
     },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
    },
  },
});
export default LightTheme;
