import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { StepLabel, Stepper as MuiStepper, Grid, Container } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Button from "../Button/Button";

//icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Form from "../Form/Form";
import ProgressButton from "../Button/ProgressButton";
import { SaveOutlined } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",

    "& .MuiStepIcon-text": {
      fontSize: "12px",
    },

    "& .MuiStepIcon-root ": {
      width: "2rem",
      height: "2rem",
      padding: "2px",
      border: "2px solid",
      borderRadius: "50%",
    },
    "& .MuiStepIcon-active ": {
      border: "2px solid #303e67",
      color: "#303e67",
   },
    "& .MuiStepIcon-active .Mui-error ": {
     
      color: "#dc3333",
    },
  
    "& .MuiStepIcon-completed ": {
      border: "2px solid #5caa2d",
      color: "#5caa2d",
    },
    "& .MuiStepLabel-completed ": {
      color: "#5caa2d",
    },
    //Line
    "& .MuiStepConnector-alternativeLabel": {
      top: "15px",
    },
  },
  contentWrapper: {
    textAlign: "center",
   
    maxWidth: "650px",
    margin: "0 auto",
    paddingTop: "30px",
    paddingBottom: "30px",
    [theme.breakpoints.up('lg')]:{
      maxWidth: "950px",
    }
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
   
    textAlign: "left",
  },
  ctrl: {
    display: "flex",
  },
}));

const HorizentalStepper = (props) => {
  const { stepValues, handleOnChange, onSubmit,FormProvider, methods,diasableStep=false, canNavigate=false,completed=true, ErrorView } = props;

 
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = () => {
    return activeStep === stepValues.length - 1;
  };
  //handles next button
  const handleNext = (data) => {
    //e.preventDefault();
     if (isLastStep()){
       
       onSubmit(data);
      }
      else{
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
    
      //console.log(activeStep)
    

    
  };
  //handles Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Render UI of Steps as per step
  function getStepContent(stepIndex) {
    return stepValues[stepIndex] && stepValues[stepIndex].content;
  }

  //UI's Rendered By Compnent

  // if step not completed its length (here it is 3) then show auditSteps else stepCompleted
  //Steps of Audit Renders Untill Last Step
  const StepperSteps = (
    <>
      <div className={classes.instructions}>{getStepContent(activeStep)}</div>
      <Grid
        container
        justifyContent="flex-end"
        className={classes.ctlr}
        spacing={1}
      >
        {/* {activeStep === 0 || isLastStep ? (
          <Grid item>
            <Button variant="outlined" label="SAVE AS DRAFT"></Button>
          </Grid>
        ) : (
          ""
        )} */}

        {activeStep > 0 && (
          <Grid item>
            <Button onClick={handleBack} variant="outlined" label="">
              {activeStep !== 0 ? <ArrowBackIcon /> : ""} Back
            </Button>
          </Grid>
        )}

        <Grid item>
          {/* <Button variant="contained" type="submit" label="">
            {isLastStep() ? "SAVE & CONTINUE" : "Next"}
            {activeStep === stepValues.length - 1 ? "" : <ArrowForwardIcon />}
          </Button> */}
          <ProgressButton variant="contained" type="submit" label="" disabled={diasableStep}>
          {isLastStep() ? "SAVE & CONTINUE" : "Next"}
          { <ArrowForwardIcon />}
            {/* {activeStep === stepValues.length - 1 ? <SaveOutlined/> : <ArrowForwardIcon />} */}
          </ProgressButton>
        </Grid>
      </Grid>
    </>
  );
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
    //for non-linear navigation
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
  return (
    <FormProvider {...methods}>
    <Form  onSubmit={methods.handleSubmit(handleNext)}>

   <Container>
   <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <MuiStepper activeStep={activeStep} alternativeLabel nonLinear={canNavigate}>
            {stepValues.map((item, index) => {
               const labelProps = {};
               if (isStepFalied() && activeStep == index) {
                labelProps.error = true;
              }
              return(
              <Step key={item.id}>
                 {canNavigate ?
                <StepLabel onClick={handleStep(index)} completed={activeStep!==index && completed[index]} >
                {item.label}

            </StepLabel>
            :
                <StepLabel {...labelProps}>{item.label}</StepLabel>
                 }
              </Step>
              );
})}
          </MuiStepper>
{ErrorView}
          {activeStep !== stepValues.length && StepperSteps}
        </div>
      </div>
   </Container>
    
    </Form>
    </FormProvider>
  );
};
export default HorizentalStepper;
