import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab/";
import { FormControl, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "5px 0px",
  },
  controlLabel: {
    lineHeight: "100%",
    fontWeight: 500,
  },
  error__label: {
    color: theme.palette.error.main,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
      textTransform:"capitalize",
    },
  },
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
        background: '#303e6742'
    }
}
}));
const AutoComplete = 
  (
    {
      name,
      label,
      isMulti,
      onBlur,
      value,
      variant,
      onChange,
      options,
      errors,
      helperText,
      prompt,
      placeHolder,
      getOptionLabel,
      isRequiredLable,
      isLoading,
      ...others
    }
    
  ) => {
    const classes = useStyles();

    const [inputValue, setInputValue] = React.useState("");


    return (
      <FormControl component={"fieldset"}>
        {prompt && (
          <Typography
            className={classes.controlLabel}
            component={"label"}
            variant="subtitle1"
          >
            {prompt}
            {isRequiredLable && <span className={classes.error__label}>*</span>}
          </Typography>
        )}
        <div className={classes.formControl}>
          <MuiAutocomplete
            multiple={isMulti}
            limitTags={1}
            size="small"
            name={name}
            id={name}
            options={options}
            value={value}
          defaultValue={value}
            //filterSelectedOptions={true}
          //  disablePortal
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            // getOptionLabel={(option) => option}
            autoHighlight
            loading={isLoading}
            loadingText="Loading..."
            noOptionsText="No Result Found"
            {...others}
            getOptionLabel={getOptionLabel}
            
            // defaultValue={options.find(v => v.text[0])}
            renderInput={(params) => (
              <TextField
                {...params}
                label={!prompt && label}
                variant={variant || "outlined"}
                placeholder={placeHolder}
                onBlur={onBlur}
                className={classes.customTextField}
                {...(errors && { error: true, helperText: helperText })}
              />
            )}
          />
        </div>
      </FormControl>
    );
  };
AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.any.isRequired,
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
  handleAuto: PropTypes.any,
  prompt: PropTypes.any,
};
export default AutoComplete;
