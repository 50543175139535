
import { TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PropTypes from 'prop-types';
const useStyles=makeStyles((theme)=>({
  root: {
     verticalAlign:" top",
   
        },
      disabled:{
       
        '& .MuiTableCell-body':{
color:'#9f9f9f',
cursor:'not-allowed',

        },
      }
 
}))
const StyledTableRow = (props) => {
  const {isHover=false, children, isDisabled=true, ...others}=props;
 
  const classes=useStyles();

  return <TableRow size="small" className={!isDisabled ? (classes.disabled +" "+ classes.root) :classes.root} hover={isHover}
  {...others}
  >
{children}
  </TableRow>;
};
StyledTableRow.propTypes={
isHover:PropTypes.bool,
children:PropTypes.node,
isDisabled:PropTypes.bool,
};
export default StyledTableRow;
