import { makeStyles } from "@material-ui/core/styles";
import  PropTypes from "prop-types";
import {  Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    padding: "10px",
   
    borderBottom: `0.5px solid ${theme.palette.border.main}`,
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pageTitle: {
    paddingRight: "5px",
    paddingLeft: "5px",
    color: "#303E67",
  },
  filterCategory: {
    display: "flex",
    color: "#3c44b1",
    alignItems: "center",
   flexGrow: 1,
  //   '& button':{
  //     height:'40px',
  // },
  },
}));

const FilterBar = (props) => {
  const classes = useStyles();
  const { label, children, ...others } = props;
  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.pageHeader}>
        {label &&
        <div className={classes.pageTitle}>
        <Typography variant="h6" component="div">
          {label}
        </Typography>
      </div>
        }
        
        <div className={classes.filterCategory}>{children}</div>
      </div>
    </Paper>
  );
};
FilterBar.prototypes={

label:PropTypes.string,
children:PropTypes.node
};
export default FilterBar;
