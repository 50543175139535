
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles=makeStyles(theme=>({
  root:{
    color:"inherit",
   padding:"3px 2px 4px 8px",
  //  'white-space': 'break-spaces',
  "&:last-child": {
    display: "flex",
    width:"100%",
  },
  "& .MuiRadio-root": {
    paddingTop: "0px !important",
  },
  "& .PrivateSwitchBase-input": {
    paddingTop: "0px",
  },
  "& .MuiFormControlLabel-root": {
    alignItems: "flex-start",
  },
  },
  colSpanRoot:{
    padding:"3px 2px 4px 8px",
    // 'white-space': 'break-spaces',
  },
   
  
}))
const StyledTableCell = ({children, size,colSpan, ...others}) => {

  const classes=useStyles();
  const style=colSpan ?classes.colSpanRoot: classes.root;
  return <TableCell className={style} colSpan={colSpan} sortDirection={'asc'} size={size ||'small'} {...others}>
   {children}
     
  </TableCell>;
};

export default StyledTableCell;

