import axios from "axios";
import { refresh } from "../Request/authApi";
import { getAccessToken, setAccessToken } from "../../accessToken";
export const masterService = axios.create({
baseURL:process.env.REACT_APP_MASTER_SERVICE_URL,
headers: {'Ocp-Apim-Subscription-Key':process.env.REACT_APP_SUBSCRIPTION_KEY,
'Ocp-Apim-Trace':true},
withCredentials: false,
});
masterService.interceptors.request.use(
  (request) => {
    request.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
masterService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
   if (error?.response.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await refresh(getAccessToken());
      setAccessToken(newAccessToken.data);
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken.data}`;
      return masterService(prevRequest);
    }
    else if (!error?.response) {
      error.response.data="Internal Server Error!";
    }
    else if(error?.response.status>=500){
      error.response.data="Internal Server Error!"
    }
    else if(typeof (error?.response?.data) !=="string"){
      error="Internal Server Error";
     }
    return Promise.reject(error);
  }
);
