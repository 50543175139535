import React from "react";
import PropTypes from 'prop-types';
//material ui import
import { makeStyles, Button as MuiButton, CircularProgress, Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

//CSS STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth:'0',
   minHeight:'35.5px',   
    padding:'5px 15px',
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    '& .MuiSvgIcon-root':{
      color:"inherit",

    },
  },
  wrapper: {
   
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const ProgressButton = (props) => {
  const classes = useStyles();
  const { label,title, color, variant, onClick, className, size, isFullwidth, isProgress=false, disabled=false,...other } = props;
 
  return (
    <span className={classes.wrapper}>
{title ? <Tooltip title={title} aria-label={title} arrow>
  <span>
<MuiButton
      className={classes.root + " " + (className || "")}
      variant={variant || 'outlined'}
      color={color || "primary"}
      size={size || "medium"}
      onClick={onClick}
      disabled={disabled || isProgress}
      {...other}
      fullWidth={isFullwidth || false}
      disableElevation
    >
     {label || props.children}
    </MuiButton>
    </span>
</Tooltip>:
    <MuiButton
    className={classes.root + " " + (className || "")}
    variant={variant || 'outlined'}
    color={color || "primary"}
    size={size || "medium"}
    onClick={onClick}
    disabled={disabled || isProgress}
    {...other}
    fullWidth={isFullwidth || false}
    disableElevation
  >
   {label || props.children}
  </MuiButton>}
     {isProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </span>
  );
};
ProgressButton.propTypes={
  label: PropTypes.string.isRequired,
  color:PropTypes.string,
  disabled:PropTypes.bool,
  isProgress:PropTypes.bool,
  variant:PropTypes.oneOf(['contained','outlined','text']),
  onClick:PropTypes.func,
  className:PropTypes.string,
  size:PropTypes.string,
  isFullwidth:PropTypes.bool
};
export default ProgressButton;
