import React, { Component } from 'react'
import ShowError from './ShowError';


  export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    // static getDerivedStateFromError(error) {
    //   // Update state so the next render will show the fallback UI.
    //   return { hasError: true };
    // }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
     // logErrorToMyService(error, errorInfo);
   
     this.setState({hasError:true})
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ShowError/>;
      }
  
      return this.props.children; 
    }
  }  