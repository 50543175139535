import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, makeStyles, Typography } from '@material-ui/core';
import {getUtcDate} from '../../hooks/useDateValue';
const useStyles = makeStyles((theme)=>({
  formControl: {
    margin: "5px 0px",
  },
    controlLabel:{
      lineHeight:'100%',
      fontWeight:500,
    },
    error__label: {
      color: theme.palette.error.main,
    },
    customTextField: {
       "& input::placeholder": {
        fontSize: "14px",
        textTransform:"capitalize",
      },
      "& MuiFormHelperText-root Mui-error" :{
        textTransform:"capitalize",
      },
    }
  
  }));
export default function DatePicker(props) {
    const classes = useStyles();
    const { name, label, value, dateFormat, onChange,prompt, placeholder, disableFuture, disablePast, minDate,isRequiredLable,...others } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl component="fieldset">
        {prompt && (
      <Typography className={classes.controlLabel} component={"label"}  variant="subtitle1"
    
       >           
        {prompt}
        {isRequiredLable && <span className={classes.error__label}>*</span>}
      </Typography>
    
    )}
     <div className="formControl">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker  variant="inline" inputVariant="outlined"
            autoOk={true}
            clearable
            className={classes.customTextField}
                label={label}
                format={dateFormat || "dd/MM/yyyy"}
                name={name}
                value={value}
                onChange={date => onChange(convertToDefEventPara(name, date))}
                placeholder={placeholder}
               fullWidth
               disableFuture={disableFuture}
               disablePast={disablePast}
               minDate={minDate}
               {...others}
               
               inputProps={{
                 readOnly:true,
               }}
            />
        </MuiPickersUtilsProvider>
        </div>
        </FormControl>
    )
}