import axios from "axios";
import { getAccessToken, setAccessToken } from "../../accessToken";
import { refresh } from "../Request/authApi";
const accessToken = getAccessToken();
export const authService = axios.create({
      baseURL:process.env.REACT_APP_AUTH_SERVICE_URL,
       headers: {'Ocp-Apim-Subscription-Key':process.env.REACT_APP_SUBSCRIPTION_KEY,
       'Ocp-Apim-Trace':true},
  withCredentials: false,
});
authService.interceptors.request.use(
  (request) => {
           if (request.url?.includes("ChangePassword") || request.url?.includes("InfoByAccessToken") ) {
      
      request.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
authService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (prevRequest.url.includes("InfoByAccessToken") || prevRequest.url.includes("ChangePassword") || prevRequest.url.includes("ResetLink") || prevRequest.url.includes("OTUVerification")) {
    

      if (error?.response.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const newAccessToken = await refresh(getAccessToken());
        setAccessToken(newAccessToken.data);
        prevRequest.headers["Authorization"] = `Bearer ${newAccessToken.data}`;
        return authService(prevRequest);
      }
      else if (!error?.response) {
        error.response.data="Internal Server Error!";
      }
    }
   else if (!error?.response) {
      error.response.data="Internal Server Error!";
    }
    else if(error?.response.status>=500){
      error.response.data="Internal Server Error!"
    }
    else if(typeof (error?.response?.data) !=="string"){
      error="Internal Server Error";
     }
     
    return Promise.reject(error);
  }
);
