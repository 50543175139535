import React from "react";
import { motion } from "framer-motion";
const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1,
  transition:{duration: 0.2}
  },
  exit: { opacity: 0 },
};
const AnimationFade = ({ children, className }) => {
  return (
    <motion.div
      {...(className && {className:className})}
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
     
    >
      {children}
    </motion.div>
  );
};
export default AnimationFade;
