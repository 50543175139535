//#region
// <summary>
// Manage Routes and set pages/components for that routes
//</summary>
//#endregion
import React from "react";
import loadable from "@loadable/component";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthRequire from "../components/Authentication/AuthRequire";
import UserRoutes from "./UserRoutes";
import SuperAdminRoutes from "./SuperAdminRoutes";
const PageNotFound =loadable(()=> import("../components/Error/PageNotFound"));
const Unauthorised = loadable(() =>
import("../components/UnAuthorised/Unauthorised")
);

const Layout = loadable(() => import("../components/Layout/Layout"));
const LoginPage = loadable(() => import("../pages/LoginPage"));
const Dashboard = loadable(() => import("../pages/Dashboard"));
const ProfilePage=loadable(()=>import("../pages/ProfilePage"));
const ChangePassword=loadable(()=>import("../components/Profile/ChangePassword"));
const ProfileDetails =loadable(()=>import('../components/Profile/ProfileDetails'));
const ForgetPassword=loadable(()=>import("../pages/ForgetPasswordPage"));
const ResetPassword=loadable(()=>import("../pages/ResetPasswordPage"));
const NavigationRoutes = () => {
  return (
  
      <Routes>
        <Route path="/pageNotFound" element={<PageNotFound />} />
        <Route path="/unauthorised" element={<Unauthorised />} />
        <Route path="login" element={<LoginPage />}></Route>
        <Route path="forgotpassword" element={<ForgetPassword/>}></Route>
        <Route path="forgotpassword/:resetToken" element={<ResetPassword/>}></Route>
        <Route element={<AuthRequire allowedRoles={["Admin","SuperAdmin"]} />}>
        <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />}></Route>
        <Route path='profile' element={<ProfilePage/>}>
      <Route index element={<ProfileDetails/>}></Route>
      <Route path='change-password' element={<ChangePassword/>}></Route>
    </Route>
        <Route path="/*" element={<UserRoutes />}></Route>
        <Route path="/subscribers/*" element={<SuperAdminRoutes/>}></Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/pageNotFound" />} />
      </Routes>
   
  );
};

export default NavigationRoutes;
