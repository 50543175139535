//#region summary
//deatials of subscriber
//#endregion
import {
    makeStyles,
    Container,
  } from "@material-ui/core";
  import React from "react";
  import {
    StyledTable,
    StyledTableBody,
    StyledTableCell,
    StyledTableRow,
  } from "../../controls";
   const useStyles = makeStyles((theme) => ({
    info: {
      padding: "10px",
    },
    sectinonHeading: {
      fontWeight: "700",
    },
    colName: {
      width: "200px",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    colValue: {
      minWidth: "300px",
    },
    customListItem:{
      paddingTop:"2px",
      paddingBottom:"2px",
      
    },
  }));
  const SubscriberDetailsForm = ({data}) => {
    const classes = useStyles();
    return (
           <Container className={classes.info}>
              <StyledTable>
                <StyledTableBody>
                  <StyledTableRow>
                    <StyledTableCell className={classes.colName}>
                      Company Name
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {data.companyName ? data.companyName : "N/A"}
                    </StyledTableCell>
               </StyledTableRow>
               <StyledTableRow>
               <StyledTableCell className={classes.colName}>
                      Subscriber Name
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {data.firstName ?  (data.title?.name + " " + data.firstName + " " + data.lastName) : "N/A"}
                    </StyledTableCell>
               </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className={classes.colName}>
                      Mobile Number
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {data.mobileNumber ? data.mobileNumber : "N/A"}
                    </StyledTableCell>
                   </StyledTableRow>
                   <StyledTableRow>
                   <StyledTableCell className={classes.colName}>
                      E-Mail Id
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {data.emailId ? data.emailId :"N/A"}
                    </StyledTableCell>
                   </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className={classes.colName}>
                      User Id
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {data.userName ? data.userName :"N/A"}
                    </StyledTableCell>
                   </StyledTableRow>
                   <StyledTableRow>
                   <StyledTableCell className={classes.colName}>
                     Company Logo
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      {(data.companyLogoPath && data.orgCompanyLogoFileName) ? data.orgCompanyLogoFileName : "N/A"}
                    </StyledTableCell>
                   </StyledTableRow>
            </StyledTableBody>
              </StyledTable>
            </Container>
    );
  };
  
  export default SubscriberDetailsForm;
  