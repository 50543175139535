//#region 
// <summary>
// axios api for users
//masterService base url used to set base url
// Master Api To fetch/add/update/delete
//  fetch is used in pagination/search/get by using qry
//</summary> 
//#endregion
import  {excelService} from "../Config/excelService";
import {masterService} from "../Config/masterServices";
export const getRoles = (qry,abortSignal) => {
  
  const endUrl =
    "/MasterApi/Roles/List";

  return masterService({
    url: endUrl,
    data:qry,
    method: "post",    
    signal:abortSignal,
  });
};
export const getActiveRoles = () => {
  const endUrl =
    "/MasterApi/Roles/All";

  return masterService({
    url: endUrl,
  
    method: "get",
  });
};
export const getPermissions = () => {
  const endUrl =
    `/MasterApi/Roles/PermissionsList`;

  return masterService({
    url: endUrl,
    method: "get",
  });
};
export const getRolePermissions = (roleId) => {
  const endUrl =
    `/MasterApi/Roles?id=${roleId}`;

  return masterService({
    url: endUrl,
    method: "get",
  });
};
export const asignPermission=(values)=>{
  const endUrl='/MasterApi/Roles/AssignPermissions/';
  return masterService({
    url:endUrl,
    data:values,
    method:"post",
  });
}
export const getRolesWithDefaultPermission = (abortSignal) => {
  const endUrl =
    "/MasterApi/Roles/DefaultPermissions";

  return masterService({
    url: endUrl,
  
    method: "get",
    signal:abortSignal,
  });
};
export const getTitles = (abortSignal) => {
  const endUrl =
    "/MasterApi/Users/Titles";

  return masterService({
    url: endUrl,      
    method: "get",
    signal:abortSignal,
  });
};
export const getUsers = (qry, abortSignal) => {
  const endUrl =
    "/MasterApi/Users/List";

  return masterService({
    url: endUrl,
    data:qry,
    method: "post",
    signal:abortSignal,
  });
};

export const getUserById= (value,abortSignal) => {
  const endUrl =`/MasterApi/Users?id=${value}`;

  return masterService({
    url: endUrl,
    method: "get",
    signal:abortSignal,
  });
};
export const getUserDetails= (value,abortSignal) => {
  const endUrl =`/MasterApi/Users/${value}`;

  return masterService({
    url: endUrl,
    method: "get",
    signal:abortSignal,
  });
};
export const addUsers=(value)=>{
  const endUrl =  "/MasterApi/Users";
return masterService({
  url: endUrl,
  data:value,
  method: "post",
//withCredentials:true
});
}
export const toggleUser=(value)=>{
  const endUrl =  "/MasterApi/Users/Toggle";
return masterService({
  url: endUrl,
  data:value,
  method: "put",

});
}

  export const getUsersByRole = () => {
    const endUrl =
      "/MasterApi/Users/All";
  
    return masterService({
      url: endUrl,
           method: "get",
    });
  };

  export const uploadFile=(file)=>{
    const uploadUrl="/ExcelApi/Users/UploadUsers";
   
    return excelService({
      url: uploadUrl,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: file,
    });
  };
  export const getFiles = () => {
   
    const downloadUrl =
      "/ExcelApi/Users/DownloadUsers";
    return excelService({
      url: downloadUrl,
      method: "POST",
      responseType: "arraybuffer",
    });
  };

 
  export const getSubscribers = (qry, abortSignal) => {
    const endUrl =
      "/MasterApi/Admin/Accounts/List";
  
    return masterService({
      url: endUrl,
      data:qry,
      method: "post",
      signal:abortSignal,
    });
  };
  export const getSubscriberDetails= (value,abortSignal) => {
    const endUrl =`/MasterApi/Admin?id=${value}`;
  
    return masterService({
      url: endUrl,
      method: "get",
      signal:abortSignal,
    });
  };
  export const addSubscriber=(value)=>{
    const endUrl =  "/MasterApi/Admin";
  return masterService({
    url: endUrl,
    data:value,
    method: "post",
  });
  }
  export const getSubscriberById= (value,abortSignal) => {
    const endUrl =`/MasterApi/Admin?id=${value}`;
  
    return masterService({
      url: endUrl,
      method: "get",
      signal:abortSignal,
    });
  };
  export const toggleSubscriber=(value)=>{
    const endUrl =  "/MasterApi/Admin/Toggle";
  return masterService({
    url: endUrl,
    data:value,
    method: "put",
  
  });
  }
  export const checkExistance = (values) => {
    const endUrl = `/MasterApi/Users/CheckExistance`;
     return masterService({
      url: endUrl,
      data:values,
      method: "post",
    });
   };