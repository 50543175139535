import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import loadable from "@loadable/component";
import { AnimatePresence } from 'framer-motion';
import AuthRequire from '../components/Authentication/AuthRequire';

const UsersPage = loadable(() => import("../pages/UsersPage"));
const UsersUpload = loadable(() => import("../pages/UsersUploadPage"));
const UserDetailsPage = loadable(() => import("../pages/UserDetailsPage"));
const UserPage = loadable(() => import("../pages/UserPage"));
const RolesPage = loadable(() => import("../pages/RolesPage"));
const RolesDetailsPage = loadable(() => import("../pages/RoleDetailsPage"));
const RolePermissionsPage = loadable(() =>
  import("../pages/RolePermissionsPage")
);
const PendingCheckpointDetails = loadable(() =>
  import("../components/Checkpoints/PendingCheckpointDetails")
);
const PendingObservationDetails = loadable(() =>
  import("../components/Checkpoints/PendingObservationDetails")
);
const PendingObservations = loadable(() =>
  import("../components/Checkpoints/PendingObservations")
);
const CheckpointsPage = loadable(() => import("../pages/CheckpointsPage"));
const PendingCheckpoints = loadable(() =>
  import("../components/Checkpoints/PendingCheckpoints")
);

const ClientDetailsPage = loadable(() => import("../pages/ClientDetailsPage"));
const CheckpointDetailsPage = loadable(() =>
  import("../pages/CheckpointDetailsPage")
);
const ClientsPage = loadable(() => import("../pages/ClientsPage"));
const ClientsUploadPage = loadable(() => import("../pages/ClientsUploadPage"));
const NewClientsPage = loadable(() => import("../pages/NewClientsPage"));
const CheckpointsUploadPage = loadable(() =>
  import("../pages/CheckpointsUploadPage")
);
const NewCheckpointPage = loadable(() => import("../pages/NewCheckpointPage"));
const CheckpointAreaUploadPage = loadable(() =>
  import("../pages/CheckpointAreaUploadPage")
);
const CheckpointAreaPage = loadable(() =>
  import("../pages/CheckpointAreaPage")
);

const FrequencyMasterPage = loadable(() =>
  import("../pages/FrequencyMasterPage")
);
const LobMasterPage = loadable(() => import("../pages/LobMasterPage"));
const LobUpload = loadable(() => import("../components/LOB/LobUpload"));
const StateMasterPage = loadable(() => import("../pages/StateMasterPage"));
const States = loadable(() => import("../components/States/States"));
const StateUpload = loadable(() => import("../components/States/StateUpload"));
const Lob = loadable(() => import("../components/LOB/Lob"));
const PendingApprovalsPage = loadable(() =>
  import("../pages/PendingApprovalsPage")
);
const AuditsPage =loadable(()=>import("../pages/AuditsPage"));
const AuditsDetailsPage =loadable(()=>import("../pages/AuditDetailsPage"));
const UserRoutes = () => {
    const location=useLocation();
     return (
    <AnimatePresence exitBeforeEnter>
   <Routes key={location.key} location={location} >
   <Route element={<AuthRequire allowedRoles={["Admin"]} />}>
    <Route path="users" element={<UsersPage />}></Route>
    <Route path="users/upload" element={<UsersUpload />}></Route>
    <Route path="users/user/" element={<UserPage />}></Route>
    <Route path="users/user/:userId" element={<UserPage />}></Route>
    <Route
      path="users/details/:userId"
      element={<UserDetailsPage />}
    ></Route>
    <Route path="roles" element={<RolesPage />}></Route>
    <Route
      path="roles/details/:roleId"
      element={<RolesDetailsPage />}
    ></Route>
    <Route
      path="roles/permissions/:roleId"
      element={<RolePermissionsPage />}
    ></Route>
    <Route path="clients" element={<ClientsPage />}></Route>
    <Route path="clients/add" element={<NewClientsPage />}></Route>
    <Route
      path="clients/details/:clientId"
      element={<ClientDetailsPage />}
    ></Route>
    <Route
      path="clients/add/:clientIdValue"
      element={<NewClientsPage />}
    ></Route>
    <Route
      path="clients/upload"
      element={<ClientsUploadPage />}
    ></Route>
    <Route path="checkpoints" element={<CheckpointsPage />}></Route>
    <Route path="checkpoints" element={<PendingApprovalsPage />}>
      <Route path="pending" element={<PendingCheckpoints />}></Route>
      <Route path="pendingObservations" element={<PendingObservations />}></Route>
      <Route
        path="pending/:checkpointId"
        element={<PendingCheckpointDetails />}
      ></Route>
     
      <Route
        path="pendingObservation/:observationId"
        element={<PendingObservationDetails />}
      ></Route>
    </Route>

    <Route
      path="checkpoints/upload"
      element={<CheckpointsUploadPage />}
    ></Route>
    <Route
      path="checkpoints/new/"
      element={<NewCheckpointPage />}
    ></Route>
    <Route
      path="checkpoints/new/:checkpointId"
      element={<NewCheckpointPage />}
    ></Route>
    <Route
      path="checkpoints/details/:checkpointId"
      element={<CheckpointDetailsPage />}
    ></Route>

    <Route
      path="checkpointArea"
      element={<CheckpointAreaPage />}
    ></Route>

    <Route
      path="checkpointArea/upload"
      element={<CheckpointAreaUploadPage />}
    ></Route>

    <Route
      path="frequencyMaster"
      element={<FrequencyMasterPage />}
    ></Route>
    <Route path="lob" element={<LobMasterPage />}>
      <Route path="all" element={<Lob />}></Route>
      <Route path="upload" element={<LobUpload />}></Route>
    </Route>
    <Route path="state" element={<StateMasterPage />}>
      <Route path="all" element={<States />}></Route>
      <Route path="upload" element={<StateUpload />}></Route>
    </Route>
 <Route path='audits' element={<AuditsPage/>}></Route>
 <Route path='audits/:auditId' element={<AuditsDetailsPage/>}></Route>
    </Route>
    <Route path="*" element={<Navigate replace to="/pageNotFound" />} />
  </Routes>
  </AnimatePresence>
  )
}

export default UserRoutes