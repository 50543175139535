import { Grid } from '@material-ui/core';
import {Controller} from 'react-hook-form';
import React from 'react'
import TextField from '../../controls/Input/TextField';

const SubscriberSignupForm = ({useFormContext, methods, onExistanceValidation=()=>{}}) => {
    const {control, formState:{errors}}=useFormContext();
    
    
  return (
    <Grid container spacing={2}>
        <Grid container item sm={12} sx={12}>

        
    <Grid item sm={6} xs={12}>
    <Controller
        name="userName"
        control={control}
        rules={{required:"User Name Required",
        pattern:{value:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message:"Please Provide Valid User Name, Must be E-Mail Id"},
        maxLength:{value:100, message:"Maximum 100 characters allowed"},
        validate:(value=>onExistanceValidation(value, 'isUserName'))
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="User Name"
        placeHolder="User Name"
        isrequiredLabel={"true"}
        errors={errors?.userName}
        helperText={errors.userName?.message}
        />
      )}
        />
        </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
    <Controller
        name="password"
        control={control}
        rules={{required:"Password Required",
     minLength:{value:8, message:"Password Must be Minimum 8 Characters Long"},
       maxLength:{value:100, message:"Maximum 100 characters, Password allowed"},
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        type="password"
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="Password"
        placeHolder="User Password"
        isrequiredLabel={"true"}
        errors={errors?.password}
        helperText={errors.password?.message}
        />
      )}
        />
    </Grid>
    <Grid item sm={6} xs={12}>
    <Controller
        name="confirmPassword"
        control={control}
        rules={{required:"Confirm Password Required",

        pattern:{value:methods.watch("password"), message:""},
        validate: (val) => {
          if (methods.watch('password') != val) {
            return "Confirm Password must match Password";
          }},
     maxLength:{value:100, message:"Maximum 100 characters, confirmPassword allowed"},
      }}
      render={({field:{name, value, onChange, onBlur}})=>(
        <TextField
        name={name}
        type="password"
        value={value}
        onChange={e=>onChange(e.target.value)}
        onBlur={onBlur}
        prompt="Confirm Password"
        placeHolder="Confirm Password"
        isrequiredLabel={"true"}
        errors={errors?.confirmPassword}
        helperText={errors.confirmPassword?.message}
        />
      )}
        />
    </Grid>
        </Grid>
  )
}

export default SubscriberSignupForm