import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import {
  ActionButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  Switch,
} from "../../controls";
import { Paper } from "@material-ui/core";
import { StyledTablePagination } from "../../controls/Table";
import { useNavigate } from "react-router-dom";
import SortedTableHead from "../../controls/Table/SortedTableHead";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NoData from "../NoData/NoData";
import useColumns from "../../controls/Table/useColumns";
import useTableColFilterMenu from "../../controls/Table/useTableColFilterMenu";
import TableItemSkelton from "../../controls/Skelton/TableItemSkelton";
import TableColumnFilterMenu from "../../controls/Table/TableColumnFilterMenu";

const Header = [
  {
    id: 1,
    accessor: "rowNumber",
    label: "Sr.",
    disableSorting: true,
    disableFilter: true,
    align: "left",
    width: "60px",
  },
  {
    id: 2,
    accessor: "companyName",
    label: "Company",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "250px",
  },
  {
    id: 3,
    accessor: "fullName",
    label: "Name",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "200px",
  },

  {
    id: 4,
    accessor: "userName",
    label: "User Login Id",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "200px",
  },
  {
    id: 5,
    accessor: "mobileNumber",
    label: "Mobile Number",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "140px",
  },

  {
    id: 6,
    accessor: "modifiedByName",
    label: "Modified By",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "150px",
  },

  {
    id: 7,
    accessor: "modifiedDateUI",
    label: "Modified Date",
    disableSorting: false,
    disableFilter: false,
    align: "left",
    width: "180px",
  },

  {
    id: 8,
    accessor: "actions",
    label: "Action",
    disableSorting: true,
    disableFilter: true,
    align: "left",
    width: "0px",
  },
];
const SubscribersItem = ({
  isLoading,
  createSortHandler,
  error = "",
  records,
  count,
  order,
  orderBy,
  page,
  setPage,
  pageSize,
  setPageSize,
  onToggle,
}) => {
  const navigate = useNavigate();
  const editHanlder = (id) => {
    navigate(`/subscribers/subscriber/${id}`);
  };
  const viewHandler = (id) => {
    navigate(`/subscribers/details/${id}`);
  };
  const { ColumnlHeaders, skeletonArray } = useColumns(
    Header,
    "subscriberTableArs"
  );
  const { anchorEl, handleClose, handleFilter } = useTableColFilterMenu();
  const [header, setHeader] = useState([]);
  const handleHeaders = useCallback((col) => {
    setHeader(col);
  });
  const headers = header?.length > 0 ? header : ColumnlHeaders;
  return (
    <Paper>
      {error !== "" ? (
        <NoData message={error} />
      ) : (
        <>
          <TableColumnFilterMenu
            handleHeaders={handleHeaders}
            anchorEl={anchorEl}
            handleClose={handleClose}
            tableId={"subscriberTableArs"}
            ColumnlHeaders={ColumnlHeaders}
          ></TableColumnFilterMenu>
          <StyledTable>
            <SortedTableHead
              Header={headers}
              createSortHandler={createSortHandler}
              order={order}
              orderBy={orderBy}
              handleFilter={handleFilter}
            ></SortedTableHead>

            <StyledTableBody>
              {isLoading
                ? skeletonArray.map((item, index) => (
                    <StyledTableRow key={`subcriberItems${index}`}>
                      {headers
                        ?.filter((h) => h.show === true)
                        .map((col, colIndex) => (
                          <StyledTableCell
                            key={`col-${col?.accessor}-${colIndex}`}
                          >
                            <TableItemSkelton />
                          </StyledTableCell>
                        ))}
                    </StyledTableRow>
                  ))
                : records &&
                  records?.map((item, index) => (
                    <StyledTableRow key={item.id} isDisabled={item.isActive}>
                      <StyledTableCell>{item.rowNumber}</StyledTableCell>
                      {headers?.find((h) => h.accessor === "companyName")
                        ?.show && (
                        <StyledTableCell>
                          {item.companyName ? item.companyName : "N/A"}
                        </StyledTableCell>
                      )}
                      {headers?.find((h) => h.accessor === "fullName")
                        ?.show && (
                        <StyledTableCell>
                          {item.fullName.trim() ? item.fullName : "N/A"}
                        </StyledTableCell>
                      )}
                      {headers?.find((h) => h.accessor === "userName")
                        ?.show && (
                        <StyledTableCell>
                          {item.userName ? item.userName : "N/A"}
                        </StyledTableCell>
                      )}
                      {headers?.find((h) => h.accessor === "mobileNumber")
                        ?.show && (
                        <StyledTableCell>
                          {item.mobileNumber ? item.mobileNumber : "N/A"}
                        </StyledTableCell>
                      )}

                      {headers?.find((h) => h.accessor === "modifiedByName")
                        ?.show && (
                        <StyledTableCell>
                          {item.modifiedByName ? item.modifiedByName : "N/A"}
                        </StyledTableCell>
                      )}
                      {headers?.find((h) => h.accessor === "modifiedDateUI")
                        ?.show && (
                        <StyledTableCell>
                          {item.modifiedDateUI ? item.modifiedDateUI : "N/A"}
                        </StyledTableCell>
                      )}
                      {headers?.find((h) => h.accessor === "actions")?.show && (
                        <StyledTableCell>
                          <ActionButton
                            disabled={!item.isActive}
                            title="Edit Role Permissions"
                            onClick={(e) => editHanlder(item.id)}
                          >
                            <EditIcon />
                          </ActionButton>
                          <ActionButton
                            title="View User Information"
                            onClick={(e) => viewHandler(item.id)}
                          >
                            <VisibilityIcon />
                          </ActionButton>
                          <ActionButton>
                            <Switch
                              isChecked={item.isActive}
                              name="enableDisableItem"
                              onChangeHandler={(e) => onToggle(item)}
                            ></Switch>
                          </ActionButton>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
            </StyledTableBody>
          </StyledTable>
        </>
      )}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            minHeight: "52px",
          }}
        >
          <Skeleton component={"div"} width={200} height={30} />
        </div>
      ) : (
        count > 0 && (
          <StyledTablePagination
            page={page}
            setPage={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            setRowsPerPage={(e) => {
              setPageSize(parseInt(e.target.value, 10));
              setPage(0);
            }}
            count={count}
          />
        )
      )}
    </Paper>
  );
};

export default SubscribersItem;
