import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Skeleton } from '@material-ui/lab';

export default function StyledTablePagination(props) {
    const {page, setPage, rowsPerPage, setRowsPerPage, count}=props;
  
    const pages = [5, 10, 25, 50, 100]
 
  return (
    <>
    {count ?
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={pages}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={setRowsPerPage}
    />
    : 
    <div  style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', minHeight:'50px'}} >
    <Skeleton component={'div'} width={200} height={35} />
</div>
    }
    </>
  );
}
