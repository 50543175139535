import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import  PropTypes from 'prop-types';
const useStyles=makeStyles((theme)=>({
    root:{
        display:'flex',
        minHeight:'55px',
        background:theme.palette.secondary.main,
        paddingLeft:'10px',
        borderBottomLeftRadius:'0px',
        borderBottomRightRadius:'0px',
        borderBottom:'0.5px solid ',
        borderBottomColor:theme.palette.border.main,
        '& .MuiTypography-h6':{
            color:theme.palette.text.primary,
           fontSize:'16px',
           
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
              },
             
        },
    },
    
}));
const FormHeader = (props) => {
    const classes=useStyles();
    const {title,children}=props;
  return (
  <Paper className={classes.root} elevation={0}>
 <Grid container justifyContent='space-between' alignItems='center' >
       <Grid item >
           <Typography variant='h6' component={'h6'}>{title}</Typography>
       </Grid>
       <Grid item >
           {children}
       </Grid>
   </Grid>
  </Paper>
  )
};
FormHeader.propTypes={
title:PropTypes.string,
};
export default FormHeader