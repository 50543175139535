import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Paper } from '@material-ui/core'
import Button from '../Button/Button';
import InfoIcon from '@material-ui/icons/Info';
import Draggable from 'react-draggable';
const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    }
}))

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()
    function PaperComponent(props) {
        return (
          <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
          </Draggable>
        );
      }
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}   PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title" >
            <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id="draggable-dialog-title">
                <IconButton disableRipple className={classes.titleIcon}>
                    <InfoIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    label="No"
                   
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
                <Button
                    label="Yes"
                  
                    onClick={confirmDialog.onConfirm} />
            </DialogActions>
        </Dialog>
    )
}