//#region  summary
//subscribers filter bar handles filters values and pass to parent component
//#endregion
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Input, StyledFormGroup } from '../../controls'
import FilterBar from '../FilterBar/FilterBar'
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from '@material-ui/icons/Refresh';
const SubscribersFilterBar = ({onSearchClick, onRestClick}) => {
 //#region component states
 const [searchValues, setSearchValues] = useState('')
 //#endregion
 //#region  handles reset
 const handlesReset=()=>{
    setSearchValues('');
    onRestClick();
  }
 //#endregion
 //#region  handles search
 const handlesSearch=()=>{
    onSearchClick(searchValues);
}
 //#endregion
 
  return (
    <FilterBar>
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <StyledFormGroup >
          <Input
            placeHolder="Search Company Name"
            name="SearchCompany"
            value={searchValues}
          onChange={e=>setSearchValues(e.target.value)}
          autoComplete="off"
          />
          <Button
            type="submit"
            onClick={handlesSearch}
            label={""}
            aria-label="search"
          >
            <SearchIcon />
          </Button>
        </StyledFormGroup>
        
      </Grid>
      <Grid item>
      <Button
            type="submit"
          onClick={handlesReset}
            label={""}
            aria-label="reset"
            className={'btnHeight'}
          >
            <RefreshIcon/>
          </Button>
      </Grid>
      <Grid item sm></Grid>
         
    </Grid>
    </FilterBar>
  )
}

export default SubscribersFilterBar