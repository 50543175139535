import { Grid } from "@material-ui/core";
import React from "react";

import { Button } from "../../controls";
import { PageHeader } from "../PageHeader";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import { useNavigate, useLocation } from "react-router-dom";
const SubscribersHeader = ({subTitle}) => {
//#region navigation
const navigate = useNavigate();
const location=useLocation();
const isAtList= location.pathname==='/subscribers'
const onClickHandler = () => {
   isAtList ? navigate("/subscribers/subscriber") : navigate("/subscribers");
};
//#endregion
  return (
    <PageHeader title="Subscribers" subTitle={subTitle}>
    <Grid container spacing={1}>
        <Grid item>
          <Button
            label={isAtList ? "Create New Subscriber" : "Show Subscribers"}
            variant="contained"
            startIcon={isAtList ? <AddIcon /> : <ListIcon />}
            onClick={onClickHandler}
          ></Button>
        </Grid>
       
      </Grid>
         </PageHeader>
  );
};

export default SubscribersHeader;
