import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles, Tooltip } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        marginLeft: theme.spacing(0.5),
        // marginRight: theme.spacing(0.5),
        border:'0.5px solid',
        color:theme.palette.primary.main,
        
        padding: "6px",
    },
   
   
}))

export default function ActionButton(props) {

    const { className, children, onClick, title, disabled } = props;
    const classes = useStyles();

    return (
        title ?   <Tooltip title={title} aria-label={title} arrow>
            <span>
      <Button
      disabled={disabled|| false}
          className={classes.root + " " + className}
          onClick={onClick} >
          {children}
      </Button>
      </span>
      </Tooltip>
      : 
       <Button
      disabled={disabled|| false}
      className={classes.root + " " + className}
      onClick={onClick} >
      {children}
  </Button>
    )
};
ActionButton.propTypes={
    title:PropTypes.string,
    disabled:PropTypes.bool,
    children:PropTypes.node,
    onClick:PropTypes.func
};
