let accessToken="";

//get sessionId from session
export const setAccessToken=(token)=>{
      const sessionId =sessionStorage.getItem("ars-admin-session");
      //set access token to session storage
      sessionId && sessionStorage.setItem(sessionId, token);
      accessToken=token;
}
export const getAccessToken=()=>{
      const sessionId =sessionStorage.getItem("ars-admin-session");
      if(sessionId){  accessToken=sessionStorage.getItem(sessionId);}
      return accessToken;
}

