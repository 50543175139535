import { Backdrop, LinearProgress, makeStyles } from "@material-ui/core";
import React from "react";
import loaderImg from "../../assets/images/spinner.svg";
const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    top: 0,
    left: 0,
    right:0,
    bottom:0,
    width: "auto",
    height: "auto",
    //backgroundColor:'red',
    zIndex: theme.zIndex.drawer - 200,
    opacity: 1,
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
   // alignItems: "center",
    justifyContent: "center",
   // backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundColor: "#f8f8f8ad",
    "-webkitTapHighlightColor": "transparent",
    display:'flex',

    position: "absolute",
  },
  loader: {
   
   position:"relative",
    width:"100%",
    zIndex:"1002",

   
  },
}));
const FullPageLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}>
   <LinearProgress/>
        {/* <img src={loaderImg} /> */}
      </div>
    
    </div>
  );
};

export default FullPageLoader;
