//#region Summary
//pure functional component
//Customize  Input control accepts props for input
//#endregion Summary
import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TextField as MuiTextField } from "@material-ui/core/";

import "./input.css";
const useStyles = makeStyles((theme) => ({
  controlLabel: {
    lineHeight: "100%",
    fontWeight: 500,
  },
  error__label: {
    color: theme.palette.error.main,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
      textTransform:"capitalize",
    },
  },
  
}));
const TextField = React.forwardRef(
  (
    {
      name,
      prompt,
      isrequiredLabel,
      helperText,
      label,
      variant,
      placeHolder,
      fullWidth,
      onChange,
      errors,
      ...others
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <FormControl component="fieldset">
        {prompt && (
          <Typography
            className={classes.controlLabel}
            component={"label"}
            htmlFor={name}
            variant="subtitle1"
          >
            {prompt}
            {isrequiredLabel && <span className={classes.error__label}>*</span>}
          </Typography>
        )}
        <div className={"formControl"}>
          <MuiTextField
            placeholder={placeHolder}
            label={!prompt && label}
            variant={variant || "outlined"}
            fullWidth={fullWidth || false}
            className={classes.customTextField}
            onChange={onChange}
            name={name}
            inputRef={ref}
            {...(errors && { error:true, helperText:helperText})}
            {...others}
          />
        </div>
      </FormControl>
    );
  }
);

export default TextField;
