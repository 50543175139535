import { useEffect,  useState } from "react";
const useColumns = (COLUMNS_HEADER, lsId) => {
const [ColumnlHeaders, setColumnlHeaders] = useState([]);
useEffect(() => {
  const hiddenColumns = JSON.parse(localStorage.getItem(lsId));
     const headers = [];
    for (const key in COLUMNS_HEADER) {
      headers.push({
        id: COLUMNS_HEADER[key].id,
        accessor: COLUMNS_HEADER[key].accessor,
        label: COLUMNS_HEADER[key].label,
        disableSorting: COLUMNS_HEADER[key].disableSorting,
        disableFilter: COLUMNS_HEADER[key].disableFilter,
        align: COLUMNS_HEADER[key].align,
        width: COLUMNS_HEADER[key].width,
        show: (hiddenColumns?.includes(COLUMNS_HEADER[key].accessor) ) ? false : COLUMNS_HEADER[key]?.show ? COLUMNS_HEADER[key].show :true,
      });
    }
    setColumnlHeaders(headers);
    
   //}
    return () => {
      setColumnlHeaders([]);
    }
  }, [COLUMNS_HEADER])
  const skeletonArray = Array(10).fill("");
  return { ColumnlHeaders, skeletonArray };
};

export default useColumns;
